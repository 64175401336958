export const PODCAST_COLLECTION_FRAGMENT = `
	title
	id
	slug
	type
	getPodcasts {
		podcasts {
			title
			slug
			feedUrl
			images {
				uri
				imageType
			}
			getClips(limit: 25) {
				clips {
					id
					title
					id
					slug
					media {
						uri
						source
					}
					images {
						uri
						imageType
					}
					publishDate
					durationSeconds
					season
					episode
					description
					reference{
						... on Podcast {
							feedUrl
						}
					}
				}
			}
		}
	}
  `;
