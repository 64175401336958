import React from 'react';

const VolumeIcon = (): JSX.Element => {
  const path = (
    <g id="V4" stroke="none" strokeWidth="1">
      <g
        id="Desktop---Volume-light"
        transform="translate(-1390.000000, -661.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g id="Bottom-player" transform="translate(0.000000, 639.000000)">
          <g id="Atom-/-Icon-/-General-/-Chromecast" transform="translate(1385.000000, 15.000000)">
            <path
              // eslint-disable-next-line max-len
              d="M15.3435,7.583175 C15.59325,7.711425 15.75,7.968675 15.75,8.249925 L15.75,21.749925 C15.75,22.031175 15.59325,22.288425 15.3435,22.416675 C15.23475,22.472175 15.117,22.499925 15,22.499925 C14.84625,22.499925 14.694,22.452675 14.56425,22.360425 L9.51,18.749925 L6,18.749925 C5.586,18.749925 5.25,18.413925 5.25,17.999925 L5.25,11.999925 C5.25,11.585925 5.586,11.249925 6,11.249925 L9.51,11.249925 L14.56425,7.639425 C14.79225,7.476675 15.09375,7.454175 15.3435,7.583175 Z M22.444575,8.702925 C25.536825,12.351675 25.536825,17.648175 22.444575,21.296175 C22.296075,21.471675 22.085325,21.561675 21.872325,21.561675 C21.700575,21.561675 21.528825,21.503175 21.387825,21.383925 C21.072075,21.116175 21.032325,20.642925 21.300825,20.327175 C23.916825,17.240175 23.916825,12.759675 21.300075,9.672675 C21.032325,9.356925 21.071325,8.883675 21.387825,8.615925 C21.704325,8.348175 22.176825,8.387175 22.444575,8.702925 Z M14.25,9.707175 L10.18575,12.610425 C10.059,12.701175 9.906,12.749925 9.75,12.749925 L6.75,12.749925 L6.75,17.249925 L9.75,17.249925 C9.906,17.249925 10.059,17.298675 10.18575,17.389425 L14.25,20.292675 L14.25,9.707175 Z M20.33235,10.859775 C22.2291,13.297275 22.2291,16.702275 20.33235,19.139775 C20.18385,19.330275 19.96335,19.429275 19.73985,19.429275 C19.5786,19.429275 19.4166,19.377525 19.27935,19.271025 C18.9531,19.016775 18.89385,18.545775 19.1481,18.218775 C20.62335,16.323525 20.62335,13.676775 19.1481,11.781525 C18.89385,11.454525 18.9531,10.982775 19.27935,10.728525 C19.6056,10.474275 20.0766,10.532775 20.33235,10.859775 Z M18.22335,13.10295 C18.91485,14.27895 18.91785,15.73545 18.23085,16.90395 C18.09135,17.1417 17.84085,17.2737 17.5836,17.2737 C17.4546,17.2737 17.32335,17.2407 17.2041,17.1702 C16.8471,16.9602 16.72785,16.50045 16.93785,16.14345 C17.34885,15.44445 17.3466,14.5707 16.93035,13.8642 C16.72035,13.5072 16.83885,13.04745 17.19585,12.83745 C17.5536,12.6282 18.01335,12.7467 18.22335,13.10295 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  );

  return (
    <svg viewBox="0 0 20 16" width="26" height="20">
      {path}
    </svg>
  );
};

export default VolumeIcon;
