import { format, parseISO } from 'date-fns';
import nl from 'date-fns/locale/nl';

export function formatDate(publishDate: string): string {
  const dateFormatFNS = 'E d MMMM yyyy';
  const localDate = format(parseISO(publishDate), dateFormatFNS, {
    locale: nl,
  });
  return localDate;
}
