import React, { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { styles } from './TabItem.styles';

type TabItemProps = {
  children: ReactNode;
  className?;
};

const TabItem = ({ children }: TabItemProps): JSX.Element => {
  const useStyles = createUseStyles(styles, { name: 'TabItem' });
  const classes = useStyles();

  return <div className={classes.wrapper}>{children}</div>;
};

export default TabItem;
