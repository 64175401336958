import { reportError } from './reportError';
import { url, os, browser } from './commonData';

const reportOverallError = async (
  errorType: string
  //talpaUID: string
): Promise<void> => {
  const errorData = {
    errorType: errorType,
    pageUrl: url,
    OS: os,
    browser: browser,
    //talpaUID: talpaUID,
  };
  reportError(errorData);
};

export { reportOverallError };
