import * as cssVariables from '../../theme/cssVariables';
import { breakpoints, mediaQuery } from '../../theme/breakpoints';
import { Styles } from 'react-jss';
import { ThemeObject } from '../../theme/theme';

export default (theme: ThemeObject): Styles => ({
  contentTile: {
    display: 'flex',
    width: 277,
    height: 50,
    padding: 5,
    borderRadius: 3,
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: theme.tileHoverColor,
    },
  },
  active: {
    '& $contentTileTitle': {
      color: theme.buttonColor,
    },
  },
  contentTileText: {
    display: 'flex',
    flexDirection: 'column',
    margin: [0, 0, 0, 10],
    maxHeight: 45,
  },
  contentTileImage: {
    width: 40,
    height: 40,
    backgroundSize: 'cover',
    borderRadius: 4,
    minWidth: 40,
  },
  contentTileTitle: {
    display: 'flex',
    height: 26,
    alignItems: 'center',
    fontSize: cssVariables.fontSizes.xsmall,
    fontWeight: 700,
    lineHeight: 0.9,
    overflow: 'hidden',
    color: theme.titleTextColor,
  },
  titleEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
  },
  contentType: {
    paddingTop: 2,
    fontSize: cssVariables.fontSizes.xxxsmall,
    lineHeight: 1,
  },
  [mediaQuery.maxWidth(breakpoints.md)]: {
    contentTile: {
      width: 160,
      height: 37,
      '&:hover': {
        backgroundColor: theme.tileHoverColor,
      },
    },
    contentTileImage: {
      width: 28,
      height: 28,
      backgroundSize: 'cover',
      borderRadius: 4,
      minWidth: 28,
    },
    contentType: {
      display: 'none',
    },
  },
});
