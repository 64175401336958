export const STATION_COLLECTION_FRAGMENT = `
	title
	id
	slug
	type
	getStations {
		stations {
		    id
			title
			id
			slug
			media {
				source
				uri
			}
			images {
				uri
				imageType
			}
		}
	}
	type
  `;
