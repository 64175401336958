import logger from './logger';

interface MediaSessionInterface {
  setPositionState(state: MediaPositionState): void;
  setActionHandler(action: string, callback: () => void): void;
  playbackState: MediaSessionPlaybackState;
  metadata: MediaMetadata;
}

interface MediaSessionData {
  album?: string;
  artist: string;
  imageURL: string;
  title: string;
}

class NullMediaSession implements MediaSessionInterface {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setActionHandler(_action: MediaSessionAction, _handler: MediaSessionActionHandler): void {
    logger.warn('Cannot set media session action handler without navigator.mediaSession support');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setPositionState(_state: MediaPositionState): void {
    logger.warn('Cannot set media session action handler without navigator.mediaSession support');
  }

  get playbackState(): MediaSessionPlaybackState {
    logger.warn('Cannot get media session playbackState without navigator.mediaSession support');
    return {} as MediaSessionPlaybackState;
  }

  set playbackState(_state: MediaSessionPlaybackState) {
    logger.warn('Cannot set media session metadata without navigator.mediaSession support');
  }

  get metadata(): MediaMetadata {
    logger.warn('Cannot get media session metadata without navigator.mediaSession support');
    return {} as MediaMetadata;
  }

  set metadata(_data: MediaMetadata) {
    logger.warn('Cannot set media session metadata without navigator.mediaSession support');
  }
}

class MediaSession implements MediaSessionInterface {
  private static instance: MediaSession | null = null;
  private readonly navigatorSession = navigator.mediaSession ?? new NullMediaSession();

  private constructor() {}

  public static getInstance(): MediaSession {
    if (!MediaSession.instance) {
      MediaSession.instance = new MediaSession();
    }
    return MediaSession.instance;
  }

  public setPositionState({ duration, position }: MediaPositionState): void {
    this.navigatorSession.setPositionState({
      duration,
      position: position > duration ? duration : Math.max(0, position),
    });
  }

  public setActionHandler(action: MediaSessionAction, handler: MediaSessionActionHandler): void {
    this.navigatorSession.setActionHandler(action, handler);
  }

  public setMetadata(data: MediaSessionData): void {
    const { album, artist, imageURL, title } = data;
    const config = {
      title,
      artwork: [
        {
          src: imageURL,
        },
      ],
      album,
      artist,
    };
    try {
      this.navigatorSession.metadata = new MediaMetadata(config);
    } catch (error) {
      logger.error(error);
    }
  }

  public get metadata(): MediaMetadata {
    return this.navigatorSession.metadata;
  }

  public get playbackState(): MediaSessionPlaybackState {
    return this.navigatorSession.playbackState;
  }

  public set playbackState(state: MediaSessionPlaybackState) {
    this.navigatorSession.playbackState = state;
  }
}

export default MediaSession;
