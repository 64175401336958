import { mediaQuery, breakpoints } from './../theme/breakpoints';
import { Styles } from 'react-jss';
import { ThemeObject } from '../theme/theme';

export default (theme: ThemeObject): Styles => ({
  swimlane: {
    position: 'relative',
    display: 'flex',
    color: theme.titleTextColor,
  },
  wrapper: {
    position: 'relative',
    overflow: 'scroll',
    overflowY: 'hidden',
    'scroll-behavior': 'smooth',
    padding: [0, 20],
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&:before, &:after': {
      content: '""',
      display: 'block',
      minWidth: 40,
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  items: {
    display: 'flex',
  },
  item: {
    position: 'relative',
    height: 60,
    padding: 5,
    transition: 'width 250ms, height 250ms',
    zIndex: 5,
    cursor: 'pointer',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.7',
  },
  [mediaQuery.maxWidth(breakpoints.md)]: {
    item: {
      height: 37,
    },
  },
  [mediaQuery.maxWidth(breakpoints.xss)]: {
    item: {
      height: 60,
    },
  },
});
