import { Styles } from 'react-jss';
import * as cssVariable from '../../cssVariables';
import { breakpoints, mediaQuery } from '../theme/breakpoints';
import { ThemeObject } from '../theme/theme';
interface StyleThemePalette {
  background: string;
  backgroundSecondary: string;
  border: string;
  common: {
    white: string;
    black: string;
  };
  gradientHeader: {
    background: string;
    color: string;
  };
  navigation: {
    background: string;
    border: string;
    color: string;
    colorActive: string;
    highlight: string;
  };
  playerControls: string;
  primary: string;
  text: {
    primary: string;
    secondary: string;
    lightText: string;
    highlightedText: string;
  };
}

type Breakpoints =
  | 'xs' // extraSmall
  | 'sm' // small
  | 'md' // smallMedium
  | 'sl' // medium
  | 'ml' // large
  | 'll' // extraLarge
  | 'lg'; // extraExtraLarge

interface StyleThemeBreakpoints {
  between: (start: Breakpoints, end: Breakpoints) => string;
  down: (key: Breakpoints) => string;
  only: (key: Breakpoints) => string;
  up: (key: Breakpoints) => string;

  keys: Array<string>; // Breakpoints keys
  values: Record<Breakpoints, string>; // Breakpoints values

  width: (key: Breakpoints) => number;
}

export interface StyleTheme {
  breakpoints: StyleThemeBreakpoints;
  palette: StyleThemePalette;

  spacing: { unit: 10 };
  zIndex: {
    carousel: 10;
    default: 10;
    dialog: 500;
    iframeWall: 9999;
    mobileHeader: 20;
    modal: 30;
    modalStandalone: 70;
    navigation: 60;
    notifications: 50;
    onTop: 100;
    overlay: 100;
    player: 40;
    pushNotification: 999999;
  };
}

export default (theme: ThemeObject): Styles => ({
  container: {
    minWidth: '200px',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'stretch',
    textAlign: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    userSelect: 'none',
    height: 15,
    paddingLeft: 4,
  },
  timeContainer: {
    alignSelf: 'center',
    color: cssVariable.grey500,
    fontSize: cssVariable.fontSizes.xxsmall,
    fontFamily: 'Arial',
    width: 35,
    marginLeft: 10,
    textAlign: 'center',
  },
  trackContainer: {
    width: '100%',
    alignSelf: 'center',
    cursor: 'pointer',
    height: 32,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'visible',
    userSelect: 'none',
  },
  track: {
    width: '100%',
    height: 3,
    background: 'white',
    userSelect: 'none',
  },
  trackComplete: {
    height: 3,
    position: 'absolute',
    left: 0,
    top: 14.5,
    background: theme.buttonColor,
    userSelect: 'none',
  },
  handle: {
    backgroundColor: theme.buttonColor,
    height: 9,
    width: 9,
    borderRadius: '50%',
    position: 'absolute',
    left: 0,
    top: 11,
    userSelect: 'none',
    marginLeft: '-4.5px',
  },
  animation: {
    '& $handle': {
      transition: 'left 0.3s linear',
    },
    '& $trackComplete': {
      transition: 'width 0.3s linear',
    },
  },
  scrubberMobile: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'stretch',
    textAlign: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    userSelect: 'none',
  },
  disabled: {
    opacity: '0.7',
    pointerEvents: 'none',
  },
  label: {
    background: 'linear-gradient(to right, #ffffff00, #fff 30%, #fff 70%, #ffffff00)',
    position: 'absolute',
    top: 6,
    left: '50%',
    width: 80,
    marginLeft: -40,
    textAlign: 'center',
    fontWeight: cssVariable.fontWeights.light,
    color: cssVariable.grey600,
  },
  hideOnMobile: {},
  [mediaQuery.maxWidth(breakpoints.xs)]: {
    container: {
      minWidth: 0,
    },
  },
});
