import React, { useCallback, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { events, PlayerEvents, ActivePodcastOrStation } from '@4tn/core-audio-player-v2';
import { titleCase } from '../../util/commonUtils';
import { styles } from './NowPlaying.styles';
import Scrubber from '../Scrubber';
import useBrowser from '../../hooks/useBrowser';
import { useFlags } from '../../firebase/hooks';
import { useContainerWidth } from './NowPlaying.hooks';

interface IPlayoutHolder {
  cuePoint?: {
    cuePoint?: {
      artistName?: string;
      cueTitle?: string;
    };
  };
}
interface NowPlayingProps {
  activePodcastOrStation: ActivePodcastOrStation;
  name: string;
  isOnline: boolean;
  playoutHolder: IPlayoutHolder;
  setPlayoutHolder: (data: IPlayoutHolder) => void;
  controlsRef: React.MutableRefObject<HTMLDivElement>;
}

const NowPlaying = ({
  activePodcastOrStation,
  name,
  isOnline,
  playoutHolder,
  setPlayoutHolder,
  controlsRef,
}: NowPlayingProps): JSX.Element => {
  const useStyles = createUseStyles(styles, { name: 'NowPlaying' });
  const classes = useStyles();
  const browser = useBrowser();
  const isOnSmallViewport = browser.down(500);
  const isOnSmallMediumViewport = browser.down(820);
  const imageRef = useRef<HTMLImageElement>();
  const isPodcast = activePodcastOrStation?.contentTypeId === 'podcast';
  const containerWidth = useContainerWidth(controlsRef, imageRef, [activePodcastOrStation]);

  const formatPlayout = (info: string) => {
    if (!info) {
      return '';
    }

    const lowerCase = info.toLowerCase();
    return titleCase(lowerCase);
  };

  const onCuePoint = (data: IPlayoutHolder) => {
    setPlayoutHolder(data);
  };

  useEffect(() => {
    events.on(PlayerEvents.TRACK_CUE_POINT, onCuePoint);

    return () => {
      events.removeListener(PlayerEvents.TRACK_CUE_POINT, onCuePoint);
    };
  }, []);

  const { showScrubber } = useFlags();
  const image = activePodcastOrStation?.images?.[0];

  const renderStationInfo = useCallback(() => {
    if (isPodcast || !playoutHolder) {
      return null;
    }
    if (isOnSmallViewport) {
      return (
        <>
          <p className={classes.songName}>{formatPlayout(playoutHolder?.cuePoint?.cuePoint?.cueTitle)}</p>
          <p className={classes.artistName}>{formatPlayout(playoutHolder?.cuePoint?.cuePoint?.artistName)}</p>
        </>
      );
    }
    return (
      <>
        <p className={classes.title}>{activePodcastOrStation?.title}</p>
        <p className={classes.subtitle}>
          {formatPlayout(
            `${playoutHolder?.cuePoint?.cuePoint?.artistName} - ${playoutHolder?.cuePoint?.cuePoint?.cueTitle}`
          )}
        </p>
      </>
    );
  }, [isPodcast, playoutHolder, isOnSmallViewport]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.innerWrapper}>
        <img
          ref={imageRef}
          className={classes.image}
          src={image?.uri}
          title="Title"
          alt="Title"
          width={48}
          height={48}
        />
        <div className={classes.infoContent} style={isOnSmallMediumViewport ? { maxWidth: containerWidth } : {}}>
          {isPodcast && (
            <>
              <p className={classes.title}>{name}</p>
              <p className={classes.subtitle}>{activePodcastOrStation?.title}</p>
              {isOnSmallMediumViewport && showScrubber && (
                <Scrubber activePodcast={activePodcastOrStation} isOnline={isOnline} />
              )}
            </>
          )}
          {renderStationInfo()}
          {!isPodcast && !playoutHolder && (
            <>
              <p className={classes.titlePlaceholder}>PLACEHOLDER TEXT TOO</p>
              <p className={classes.subtitlePlaceholder}>
                THIS IS A PLACEHOLDER TEXT FOR THE BUTTON NOT TO MOVE DONT TOUCH
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NowPlaying;
