import { initializeApp } from 'firebase/app';

const firebaseConfigDevTest = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'juke-radio-web-dev.firebaseapp.com',
  projectId: 'juke-radio-web-dev',
  storageBucket: 'juke-radio-web-dev.appspot.com',
  messagingSenderId: '1079858741001',
  appId: '1:1079858741001:web:be3bc128315f4dcc1a5dd8',
  measurementId: 'G-36CMGC5NXH',
};
const firebaseConfigProd = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'juke-radio-a7000.firebaseapp.com',
  databaseURL: 'https://juke-radio-a7000.firebaseio.com',
  projectId: 'juke-radio-a7000',
  storageBucket: 'juke-radio-a7000.appspot.com',
  messagingSenderId: '351187034337',
  appId: '1:351187034337:web:a601615eb6cda0ec69d7a0',
  measurementId: 'G-H2D2TV00V6',
};

const firebaseConfigAcc = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'juke-radio-acc-bec58.firebaseapp.com',
  databaseURL: 'https://juke-radio-acc-bec58.firebaseio.com',
  projectId: 'juke-radio-acc-bec58',
  storageBucket: 'juke-radio-acc-bec58.appspot.com',
  messagingSenderId: '91471092391',
  appId: '1:91471092391:web:ad4590f5ec08ebdbcb1982',
};

let firebaseConfig;

const ENVIRONMENTS = {
  TEST: 'test',
  DEV: 'dev',
  ACC: 'acc',
  PROD: 'prod',
};

switch (process.env.REACT_APP_ENV) {
  case ENVIRONMENTS.TEST:
  case ENVIRONMENTS.DEV:
    firebaseConfig = firebaseConfigDevTest;
    break;
  case ENVIRONMENTS.ACC:
    firebaseConfig = firebaseConfigAcc;
    break;
  case ENVIRONMENTS.PROD:
    firebaseConfig = firebaseConfigProd;
    break;
  default:
    break;
}

export const firebase = initializeApp(firebaseConfig);
