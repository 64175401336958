import { ActivePodcastOrStation } from '@4tn/core-audio-player-v2';
import { v4 as uuid } from 'uuid';
import cloneDeep from '../../util/cloneDeep';

const INITIAL_STATE = {
  sessionId: '',
  clipId: '',
  ourClipId: '',
  seqNumber: 1,
  events: [],
};
const OMNY_LINK = 'https://traffic.omny.fm/api/consumption/events?organizationId=';

const state = cloneDeep(INITIAL_STATE);

function sendDataToOmny(data) {
  fetch(OMNY_LINK + state.events[0]?.OrganizationId, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
    .then((res) => {
      return res.json();
    })
    .then(() => resetState());
}

function resetState() {
  Object.assign(state, cloneDeep(INITIAL_STATE));
}

export function omnyEventPageHide(activePodcastOrStation: ActivePodcastOrStation, time: number): void {
  if (!state.events.length) {
    return;
  }
  const isOmny = activePodcastOrStation?.reference?.feedUrl?.split('/')[4] === 'playlist';
  if (state.events.length % 2 !== 0 && isOmny) {
    state.seqNumber++;

    state.events.push({
      OrganizationId: state.events[0]?.OrganizationId,
      ClipId: state.clipId,
      SessionId: state.sessionId,
      Type: 'Stop',
      Position: time,
      SeqNumber: state.seqNumber,
      Timestamp: `${Math.round(new Date().getTime() / 1000)}`,
    });
  }
  const data = {
    Source: 'CustomWeb',
    Events: state.events,
    Completed: true,
  };

  const headers = { 'Content-Type': 'application/json' } as BlobPropertyBag;
  const blob = new Blob([JSON.stringify(data)], headers);
  navigator.sendBeacon(OMNY_LINK + state.events[0]?.OrganizationId, blob);
}

export async function omnyEventsStart(activePodcastOrStation: ActivePodcastOrStation, time: number): Promise<void> {
  const isOmny = activePodcastOrStation?.reference?.feedUrl?.split('/')[4] === 'playlist';

  if (isOmny) {
    const currentOrganizationId = activePodcastOrStation?.reference?.feedUrl?.split('/')[5];
    if (state.ourClipId && activePodcastOrStation?.id !== state.ourClipId) {
      const data = {
        Source: 'CustomWeb',
        Events: state.events,
        Completed: true,
      };

      await fetch(OMNY_LINK + currentOrganizationId, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then(() => {
          state.sessionId = uuid();
          state.ourClipId = activePodcastOrStation?.id;
          state.events = [];
          state.seqNumber = 1;
        });
    }
    if (!state.ourClipId) {
      state.ourClipId = activePodcastOrStation?.id;
      state.sessionId = uuid();
    }

    if (state.seqNumber !== 1) {
      state.seqNumber++;
    }

    await fetch(activePodcastOrStation?.reference?.feedUrl)
      .then((response) => response.text())
      .then((str) => new window.DOMParser().parseFromString(str, 'text/xml'))
      .then((data) => {
        const items = data?.querySelectorAll('item');
        items.forEach((item) => {
          if (item?.querySelector('title')?.textContent === activePodcastOrStation?.title) {
            state.clipId = item?.querySelector('enclosure')?.getAttribute('url')?.split('/')[7];
          }
        });
      });

    state.events.push({
      OrganizationId: currentOrganizationId,
      ClipId: state.clipId,
      SessionId: state.sessionId,
      Type: 'Start',
      Position: state.seqNumber === 1 ? 0 : time,
      SeqNumber: state.seqNumber,
      Timestamp: `${Math.round(new Date().getTime() / 1000)}`,
    });
  }

  if (!isOmny && state.events.length && activePodcastOrStation?.contentTypeId === 'station') {
    const data = {
      Source: 'CustomWeb',
      Events: state.events,
      Completed: true,
    };
    sendDataToOmny(data);
  }
}

export function omnyEventsStop(activePodcastOrStation: ActivePodcastOrStation, time: number, isPlaying: boolean): void {
  const isOmny = activePodcastOrStation?.reference?.feedUrl?.split('/')[4] === 'playlist';
  if (isOmny && isPlaying && state.clipId) {
    addOmnyEvent(activePodcastOrStation, time, 'Stop');
  } else if (state.clipId && state.events.length && state.events.length % 2 !== 0) {
    addOmnyEvent(activePodcastOrStation, time, 'Stop', true);
    const data = {
      Source: 'CustomWeb',
      Events: state.events,
      Completed: true,
    };
    sendDataToOmny(data);
  }
}

export function omnyEventsStreamEnded(activePodcastOrStation: ActivePodcastOrStation, time: number): void {
  const isOmny = activePodcastOrStation?.reference?.feedUrl?.split('/')[4] === 'playlist';
  if (isOmny) {
    addOmnyEvent(activePodcastOrStation, time, 'Stop');
    const data = {
      Source: 'CustomWeb',
      Events: state.events,
      Completed: true,
    };
    sendDataToOmny(data);
  }
}

export function addOmnyEvent(
  activePodcastOrStation: ActivePodcastOrStation,
  time: number,
  type: 'Start' | 'Stop',
  condition = false
): void {
  const isOmny = activePodcastOrStation?.reference?.feedUrl?.split('/')[4] === 'playlist';

  if (isOmny || condition) {
    state.seqNumber++;
    state.events.push({
      OrganizationId: state.events[0]?.OrganizationId,
      ClipId: state.clipId,
      SessionId: state.sessionId,
      Type: type,
      Position: time,
      SeqNumber: state.seqNumber,
      Timestamp: `${Math.round(new Date().getTime() / 1000)}`,
    });
  }
}
