import React from 'react';

const SkipIcon = (): JSX.Element => {
  return (
    <svg viewBox="0 0 28 30">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polyline strokeWidth="2" stroke="currentColor" points="8.83333333 6.93939394 3 6.93939394 3 1" />
        <path
          stroke="currentColor"
          // eslint-disable-next-line max-len
          d="M4.06708876,5.88572903 C6.52813422,2.96243871 10.1636797,1.11308387 14.2197251,1.11308387 C21.6318615,1.11308387 27.6396948,7.2879871 27.6396948,14.9062452 C27.6396948,22.5240516 21.6318615,28.6994065 14.2197251,28.6994065 C10.0630585,28.6994065 6.34754331,26.7570194 3.88605846,23.7072774"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default SkipIcon;
