import { RemoteConfig, fetchAndActivate, getAll, getRemoteConfig, getValue } from 'firebase/remote-config';
import { logger } from '../util';
import { DEFAULT_REMOTE_CONFIG_FLAGS } from './const';
import { FlagName, IDefaultRemoteConfigFlags } from './types';
import { firebase } from './firebase';
import storage from '../util/storage';

class FlagService {
  static cachedResult: IDefaultRemoteConfigFlags;
  private defaultFlags: IDefaultRemoteConfigFlags;

  constructor() {
    this.defaultFlags = DEFAULT_REMOTE_CONFIG_FLAGS;
  }

  public async getAllFlags(remoteConfig: RemoteConfig): Promise<IDefaultRemoteConfigFlags> {
    if (FlagService.cachedResult) {
      return FlagService.cachedResult;
    }

    if (!storage.isEnabled()) {
      return DEFAULT_REMOTE_CONFIG_FLAGS;
    }

    try {
      await fetchAndActivate(remoteConfig);

      const remoteFlags = getAll(remoteConfig);
      const newFlags = {
        ...this.defaultFlags,
      };

      for (const [key, config] of Object.entries(remoteFlags)) {
        newFlags[key] = config.asBoolean();
      }
      FlagService.cachedResult = newFlags;
      window.featureFlags = newFlags;
      return newFlags;
    } catch (error) {
      logger.error(error);
    }
  }

  public async getFlagValue(flagName: FlagName): Promise<boolean> {
    if (FlagService.cachedResult && typeof FlagService.cachedResult[flagName] !== 'undefined') {
      return FlagService.cachedResult[flagName];
    }

    if (!storage.isEnabled()) {
      return DEFAULT_REMOTE_CONFIG_FLAGS[flagName];
    }

    const remoteConfig = getRemoteConfig(firebase);

    try {
      await fetchAndActivate(remoteConfig);
      return getValue(remoteConfig, flagName).asBoolean();
    } catch (error) {
      logger.error(error);
    }
  }
}

export default FlagService;
