/* eslint-disable */
import React from "react";

import withSvgIcon from "../../hoc/withSvgIcon";

const svg = (
  <svg width="15px" height="28px" viewBox="0 0 15 28">
    <g stroke="none" strokeWidth="1" fill="none">
      <g
        id="Arrow"
        transform="translate(-80.000000, -73.000000)"
        fill='currentColor'
      >
        <path
          d="M81.5,100.5 C81.116,100.5 80.732,100.353 80.4395,100.0605 C79.853,99.474 79.853,98.526 80.4395,97.9395 L91.379,87 L80.4395,76.0605 C79.853,75.474 79.853,74.526 80.4395,73.9395 C81.026,73.353 81.974,73.353 82.5605,73.9395 L94.5605,85.9395 C95.147,86.526 95.147,87.474 94.5605,88.0605 L82.5605,100.0605 C82.268,100.353 81.884,100.5 81.5,100.5"
          id="chevron-right-icon"
        />
      </g>
    </g>
  </svg>
);

export default withSvgIcon(svg);
