import { reportError } from './reportError';
import { url, os, browser } from './commonData';

const reportFailedAPIRequest = async (
  errorType: string,
  apiRequest: string,
  apiUrl: string
  //talpaUID: string
): Promise<void> => {
  const errorData = {
    errorType: errorType,
    apiRequest: apiRequest,
    apiUrl: apiUrl,
    pageUrl: url,
    OS: os,
    browser: browser,
    //talpaUID: talpaUID,
  };
  reportError(errorData);
};

export { reportFailedAPIRequest };
