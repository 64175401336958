import { environments } from '../components/ApiSwitch/ApiSwitch.const';
import logger from '../util/logger';
import storage from './storage';

const VALID_ENVIORMENTS = environments.JUKE_API_ENV.concat(environments.UNIVERSAL_API_ENV);

/**
 * returns environment variable based on stored environment setting in local storage
 * @param {string} key - key name of variable in .env file
 * @param {string} storedEnvironment - key name in local storage
 */
const getEnvironmentVariable = (key, storedEnvironment) => {
  if (process.env.REACT_APP_ENABLE_API_SWITCH !== 'true') {
    return process.env[`REACT_APP_${key}`];
  }
  const environment = storage.getItem(storedEnvironment);
  if (environment) {
    if (!VALID_ENVIORMENTS.includes(environment)) {
      logger.error(`enviroment ${environment} not supported`);
      return process.env[`REACT_APP_${key}`];
    }
    if (!process.env[`REACT_APP_${key}_${environment}`]) {
      logger.error(`variable ${key} not found for ${environment}`);
      return process.env[`REACT_APP_${key}`];
    }
    return process.env[`REACT_APP_${key}_${environment}`];
  }
  return process.env[`REACT_APP_${key}`];
};

export default getEnvironmentVariable;
