import React, { useEffect, ReactElement, ChangeEvent } from 'react';
import styles from './VolumeSlider.styles';
import { createUseStyles } from 'react-jss';
import { player } from '@4tn/core-audio-player-v2';
import Speaker from '../svg/Speaker';
import { isSafari } from '../../util/device';
import { streamVolumeEventHandler } from '../../analytics/event-handlers/streamVolumeTealiumEventHandler';
import storage from '../../util/storage';

let volumeTimer = 0;

type VolumeSlideProps = {
  setVolumeLevel: (volume: number) => void;
  isLoading: boolean;
  volumeLevel: number;
};

const useStyles = createUseStyles(styles, { name: 'VolumeSlider' });
const VolumeSlider = ({ setVolumeLevel, volumeLevel, isLoading }: VolumeSlideProps): ReactElement => {
  const classes = useStyles();
  const { currentTime, playbackMode, isPlaying } = player;

  useEffect(() => {
    storage.setItem('volumeLevel', volumeLevel || volumeLevel === 0 ? volumeLevel.toString() : '1');
  }, [volumeLevel]);

  const handleSetVolumeLevel = (evt: ChangeEvent<HTMLInputElement>) => {
    // parse time and immediately set state to ensure smooth UI
    const nextVolumeLevel = parseFloat(evt.target.value) as number;
    setVolumeLevel(nextVolumeLevel);
    streamVolumeEventHandler(volumeLevel, nextVolumeLevel, currentTime, playbackMode, isPlaying);

    // debounce action to avoid lag in UI and spamming of analytics
    clearTimeout(volumeTimer);
    volumeTimer = window.setTimeout(() => {
      player.setVolumeLevel(nextVolumeLevel);
    }, 50);
  };

  return (
    <div className={classes.container}>
      <div className={classes.additionalContainer}></div>
      <div className={classes.volumeSliderContainer}>
        <Speaker volumeLevel={0} className={classes.speaker} />
        <div className={`${classes.sliderHolder} ${isLoading ? classes.disabled : ''}`}>
          <input
            className={classes.volumeSlider}
            data-testid="player-bottom-volume-slider-input"
            type="range"
            min={0}
            max={1}
            disabled={isLoading}
            step={0.01}
            value={volumeLevel}
            onChange={handleSetVolumeLevel}
            aria-label="volume slider"
          />
          <div className={isSafari ? classes.progressWrapperSafari : classes.progressWrapper}>
            <div className={classes.progress} style={{ width: `${volumeLevel * 100}%` }} />
          </div>
        </div>
        <Speaker volumeLevel={1} className={classes.speaker} />
      </div>
    </div>
  );
};

export default VolumeSlider;
