import { createUseStyles } from 'react-jss';
import * as cssVariable from '../theme/cssVariables';
import { breakpoints, mediaQuery } from '../theme/breakpoints';
import { ThemeObject } from '../theme/theme';

export const styles = createUseStyles((theme: ThemeObject) => ({
  '@global': {
    '*, *:before, *:after': {
      // Use a normal understading of element sizing
      boxSizing: 'border-box',
      // Fix IE10 flex defaults
      // flex: '0 1 auto',
    },
    '#overlay': {
      backgroundColor: cssVariable.grey600,
      opacity: '0',
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: '9998',
    },
    '#preroll': {
      height: '100%',
      opacity: '0',
      maxHeight: '169px',
      position: 'absolute',
      top: '50%',
      right: '50%',
      transform: 'translate(50%, -50%)',
      zIndex: '9999',
    },
    '.jw-rightclick': { display: 'none !important' },
    App: {
      minHeight: '100%',
      minWidth: '100%',
    },
    html: {
      minHeight: '100%',
    },
    body: {
      height: '100%',
      backgroundColor: theme.backgroundColor,
      margin: 0,
      color: theme.subtitleTextColor,
      fontSize: cssVariable.fontSizes.small,
      fontFamily: cssVariable.fontFamily,
      overflowX: 'hidden',
    },
    '#root': {
      height: '100vh',
    },
    'p, span': {
      margin: 0,
      padding: 0,
    },
    // Fix img sizing (and IE11 svg default sizing)
    img: {
      height: 'auto',
      maxWidth: '100%',
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      margin: 0,
      padding: 0,
      textIndent: 0,
      listStyle: 'none',
    },
    'button, input, textarea, select': {
      font: 'inherit',
      color: 'inherit',
    },
    button: {
      font: 'inherit',
      color: 'inherit',
    },
    'a:not(:hover):not(:focus):not(:active)': {
      textDecoration: 'none',
    },
    '.triton-pixel': {
      display: 'none',
    },
  },
  appWrapper: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    width: '100%',
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    maxWidth: 300,
  },
  topPanel: {
    height: '40%',
    overflow: 'hidden',
    '& > svg': {
      position: 'absolute',
      top: '60%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      fill: theme.titleTextColor,
    },
  },
  panelTitle: {
    display: 'none',
    padding: [5, 0, 0, 33],
    color: theme.titleTextColor,
  },
  overlayActive: {},
  closeOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: [3, 5, 10, 5],
    cursor: 'pointer',
    color: theme.titleTextColor,
  },
  bottomPanel: {
    display: 'flex',
    height: '60%',
    borderTop: `1px solid ${theme.borderColor}`,
  },
  bottomPanelMobile: {
    display: 'flex',
    height: '100%',
    borderBottom: `1px solid ${theme.borderColor}`,
  },
  bottomTabsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomTabsWrapperMobile: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '50%',
  },
  radioPlayer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  radioPlayerMobile: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  topPanelMobile: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.borderColor}`,
  },
  logo: {
    alignSelf: 'center',
    paddingRight: 35.5,
    paddingLeft: 18,
  },
  logoMobile: {
    display: 'flex',
    paddingLeft: 13,
    alignItems: 'center',
  },
  logoHref: {
    display: 'inherit',
    width: 74,
    height: 40,
    minWidth: 74,
  },
  logoHrefSquare: {
    display: 'inherit',
    width: 50,
    height: 50,
    minWidth: 50,
    margin: [0, 12],
  },
  logoImage: {
    width: 74,
    height: 40,
    minWidth: 74,
  },
  logoImageSquare: {
    width: 50,
    height: 50,
    minWidth: 50,
  },
  tabListWrapper: {
    height: '100%',
    display: 'flex',
    textAlign: 'center',
  },
  tabListWrapperMobile: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    paddingRight: 5,
  },
  tabList: {
    height: '100%',
    display: 'flex',
  },

  tabItemWrapper: {
    padding: [0, 5],
    borderLeft: `1px solid ${theme.borderItemColor}`,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabItem: {
    maxWidth: 80,
    width: 80,
    cursor: 'pointer',
    zIndex: 5,
  },

  tabItemTitle: {
    fontSize: cssVariable.fontSizes.xxsmall,
    textAlign: 'center',
    lineHeight: 1.1,
    color: theme.iconsColor,
  },
  tabItemTitleEllipsis: {
    ...cssVariable.ellipsis,
  },
  svgIconWrapper: {
    '& > svg': {
      fill: theme.iconsColor,
    },
  },
  svgIconWrapperMobile: {
    display: 'flex',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.7,
  },
  [mediaQuery.minWidth(820)]: {
    closeOverlay: {
      display: 'none',
    },
    tabItem: {
      '&.react-tabs__tab--selected p': {
        color: theme.buttonColor,
      },
      '&.react-tabs__tab--selected svg': {
        color: theme.buttonColor,
      },
    },
  },

  [mediaQuery.maxWidth(breakpoints.sl)]: {
    tabItem: {
      width: 65,
    },
    tabItemTitle: {
      maxWidth: 60,
    },
  },

  [mediaQuery.maxWidth(breakpoints.ml)]: {
    logo: {
      alignSelf: 'center',
      paddingRight: 23.5,
      paddingLeft: 18,
    },
  },

  [mediaQuery.maxWidth(breakpoints.md)]: {
    topPanel: {
      display: 'none',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: theme.backgroundColor,
      zIndex: 100,
    },
    overlayActive: {
      display: 'block',
    },
    panelTitle: {
      display: 'block',
      fontSize: cssVariable.fontSizes.medium,
      fontWeight: 700,
    },
    logo: {},
    logoHref: {
      width: 55,
      height: 30,
      minWidth: 55,
    },
    logoHrefSquare: {
      width: 40,
      height: 40,
      minWidth: 40,
      margin: [5, 11, 5, 0],
    },
    logoImage: {
      width: 55,
      height: 30,
      minWidth: 55,
    },
    logoImageSquare: {
      width: 40,
      height: 40,
      minWidth: 40,
      margin: [0, 11, 0, 0],
    },
    tabItem: {
      maxWidth: 50,
      width: 50,
      minWidth: 48,
      cursor: 'pointer',
      zIndex: 5,
      border: 0,
    },
    tabItemTitle: {
      display: 'none',
    },
    svgIconWrapper: {
      paddingTop: 50,
      '& > svg': {
        top: '50%',
      },
    },
  },

  [mediaQuery.maxWidth(breakpoints.xss)]: {
    tabItem: {
      height: 40,
      width: 40,
      minWidth: 40,
    },
    svgIconWrapper: {
      '& > svg': {
        transform: 'translate(-50%,-50%) scale(0.8)',
      },
    },
  },
}));
