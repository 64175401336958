import React from 'react';
import PropTypes from 'prop-types';

const StopIcon = (): JSX.Element => (
  <svg viewBox="0 0 80 80">
    <title>Stop</title>
    <g stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
      <g>
        <circle id="Elevation" cx="40" cy="40" r="40"></circle>
        <circle id="Surface" fill="currentColor" cx="40" cy="40" r="40"></circle>
        <circle id="Border" fillRule="nonzero" cx="40" cy="40" r="40"></circle>
        <circle id="Transparancy" fillOpacity="0" fill="#000000" fillRule="nonzero" cx="40" cy="40" r="40"></circle>
      </g>
      <svg width="80px" height="80px" viewBox="0 0 40 40" version="1.1">
        <title>Atom / Icon / General / Stop_filled</title>
        <g id="Atom-/-Icon-/-General-/-Stop_filled" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
            d="M25,12 L15,12 C13.346,12 12,13.346 12,15 L12,25 C12,26.654 13.346,28 15,28 L25,28 C26.654,28 28,26.654 28,25 L28,15 C28,13.346 26.654,12 25,12"
            id="Fill-1"
            fill="white"
          ></path>
        </g>
      </svg>
    </g>
  </svg>
);

StopIcon.propTypes = {
  filled: PropTypes.bool,
};

StopIcon.defaultProps = {
  filled: false,
};

export default StopIcon;
