export const HTTP_STATUS_CODE = {
  OK: 200,
  NOT_FOUND: 404,
};

export const freewheelTimeout = 'freewheelTimeout';

export const userClickEventLabel = {
  MEDIA_LIST: 'media_list',
  EPISODE_LIST: 'episode_list',
  VOLUME: 'volume',
};
