import * as cssVariable from '../theme/cssVariables';
import { breakpoints, mediaQuery } from '../theme/breakpoints';
import { Styles } from 'react-jss';
import { ThemeObject } from '../theme/theme';

export default (theme: ThemeObject): Styles => ({
  sliderHolder: {
    lineHeight: 1,
    filter: `drop-shadow(0 0 0 ${cssVariable.transparentLightmode010})`,
  },
  volumeSlider: {
    verticalAlign: 'middle',
    margin: 0,
    padding: [0, 15],
    WebkitAppearance: 'none' /* Hides the slider so that custom slider can be made */,
    background: 'transparent',
    outline: 'none',
    WebkitTransition: '.2s',
    height: 50,
    width: 170,
    '&::-webkit-slider-thumb': {
      WebkitAppearance: 'none',
      appearance: 'none',
      width: 20,
      height: 20,
      borderRadius: 20,
      background: cssVariable.white,
      cursor: 'pointer',
      boxShadow: `-2px 0 4px 0 ${cssVariable.transparentLightmode030}`,
    },
  },
  progressWrapper: {
    position: 'absolute',
    zIndex: -1,
    bottom: 20,
    left: 20,
    height: 8,
    width: 135,
    background: theme.volumeSliderColor,
    borderRadius: 10,
  },
  progressWrapperSafari: {
    position: 'absolute',
    zIndex: -1,
    top: 25,
    right: 70,
    height: 8,
    width: 135,
    background: theme.volumeSliderColor,
    borderRadius: 10,
  },
  disabled: {
    opacity: '0.7',
  },
  progress: {
    height: 8,
    backgroundColor: theme.buttonColor,
    borderRadius: 10,
  },
  volumeSliderContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: [0, 30, 0, 17],
    justifyContent: 'right',
    height: 60,
  },
  speaker: {
    height: 50,
    borderRadius: 4,
    marginLeft: -4,
    marginRight: -4,
  },
  [mediaQuery.maxWidth(breakpoints.md)]: {
    volumeSliderContainer: {
      justifyContent: 'left',
    },
    progressWrapper: {
      position: 'absolute',
      zIndex: -1,
      bottom: 20,
      left: 20,
      height: 8,
      width: 135,
      background: cssVariable.grey200,
      borderRadius: 10,
    },
    progressWrapperSafari: {
      top: 56,
      left: 56,
    },
    additionalContainer: {
      display: 'none',
    },
  },
  container: {
    display: 'flex',
  },
  additionalContainer: {
    flex: 2,
  },
});
