import React, { ReactNode } from 'react';
import { IStationsAndPodcasts, usePlayerData } from '../hooks/usePlayerData';
import { UniversalPartnerPlayer } from '../api/interfaces';

interface DataContextProps {
  children: ReactNode;
}

interface DataContext {
  player: UniversalPartnerPlayer;
  stationsAndPodcasts: IStationsAndPodcasts;
  isLoading: boolean;
}

export const DataContextProvider = ({ children }: DataContextProps): JSX.Element => {
  const { partnerPlayer, stationsAndPodcasts, isLoading } = usePlayerData();

  return (
    <DataContext.Provider value={{ player: partnerPlayer, stationsAndPodcasts, isLoading }}>
      {children}
    </DataContext.Provider>
  );
};

export function useDataContext(): DataContext {
  const context = React.useContext(DataContext);
  if (context === undefined) {
    throw new Error(`useDataContext must be used within a DataContextProvider`);
  }
  return context;
}

export const DataContext = React.createContext(null);
