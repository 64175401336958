import * as cssVariable from '../theme/cssVariables';
import jukeLogoWhite from '../../images/JUKE-poweredby-logo-white.png';
import jukeLogoBlack from '../../images/JUKE-poweredby-logo-black.png';

const LIGHT = 'light';
const DARK = 'dark';

export interface ThemeObject {
  backgroundColor: string;
  buttonColor: string;
  titleTextColor: string;
  subtitleTextColor: string;
  iconsColor: string;
  jukeLogo: string;
  volumeSliderColor: string;
  borderColor: string;
  borderItemColor: string;
  swimlaneArrowBackgroundColor: string;
  swimlaneArrowColor: string;
  tileHoverColor: string;
}

const theme = (appearance: string, buttonColor: string): ThemeObject => {
  let themeObject = {
    backgroundColor: '',
    buttonColor: '',
    titleTextColor: '',
    subtitleTextColor: '',
    iconsColor: '',
    jukeLogo: '',
    volumeSliderColor: '',
    borderColor: '',
    borderItemColor: '',
    swimlaneArrowBackgroundColor: '',
    swimlaneArrowColor: '',
    tileHoverColor: '',
  };

  switch (appearance) {
    case LIGHT: {
      themeObject = {
        backgroundColor: cssVariable.grey100,
        buttonColor: buttonColor,
        titleTextColor: cssVariable.grey600,
        subtitleTextColor: cssVariable.grey500,
        iconsColor: cssVariable.grey500,
        jukeLogo: jukeLogoBlack,
        volumeSliderColor: cssVariable.grey300,
        borderColor: cssVariable.transparentLightmode010,
        borderItemColor: cssVariable.transparentLightmode010,
        swimlaneArrowBackgroundColor: cssVariable.swimlaneArrowLightmode,
        swimlaneArrowColor: cssVariable.grey600,
        tileHoverColor: cssVariable.transparentLightmode010,
      };
      break;
    }
    case DARK: {
      themeObject = {
        backgroundColor: cssVariable.grey600,
        buttonColor: buttonColor,
        titleTextColor: cssVariable.white,
        subtitleTextColor: cssVariable.white,
        iconsColor: cssVariable.white,
        jukeLogo: jukeLogoWhite,
        volumeSliderColor: cssVariable.grey200,
        borderColor: cssVariable.transparentDarkmode010,
        borderItemColor: cssVariable.transparentDarkmode030,
        swimlaneArrowBackgroundColor: cssVariable.swimlaneArrowDarkmode,
        swimlaneArrowColor: cssVariable.white,
        tileHoverColor: cssVariable.transparentDarkmode020,
      };
      break;
    }
    default: {
      themeObject = {
        backgroundColor: cssVariable.grey600,
        buttonColor: cssVariable.red,
        titleTextColor: cssVariable.white,
        subtitleTextColor: cssVariable.white,
        iconsColor: cssVariable.white,
        jukeLogo: jukeLogoWhite,
        volumeSliderColor: cssVariable.grey200,
        borderColor: cssVariable.transparentDarkmode010,
        borderItemColor: cssVariable.transparentDarkmode030,
        swimlaneArrowBackgroundColor: cssVariable.swimlaneArrowDarkmode,
        swimlaneArrowColor: cssVariable.white,
        tileHoverColor: cssVariable.grey600,
      };
    }
  }

  return themeObject;
};

export default theme;
