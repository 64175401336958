import { useFeatureFlag } from '../../firebase/hooks';
import { FEATURE_FLAG } from '../../firebase/types';
import { isSafari } from '../../util/device';

interface IUseSelectors {
  shouldExcludePreroll: () => boolean;
}

export const useSelectors = (): IUseSelectors => {
  const excludePrerollFlag = useFeatureFlag(FEATURE_FLAG.EXCLUDE_PREROLL);

  const shouldExcludePreroll = () => {
    // we want to exclude prerolls on safari due to autoplay issues we have on this browser
    if (isSafari) {
      return true;
    }
    const excludePrerollParam = new URLSearchParams(window.location.search).get(FEATURE_FLAG.EXCLUDE_PREROLL);

    // if excludePreroll flag is not set through search param, then we fallback to the value from firebase flag
    if (excludePrerollParam === null) {
      return excludePrerollFlag;
    }

    return excludePrerollParam === 'true';
  };

  return {
    shouldExcludePreroll,
  };
};
