import { Styles } from 'react-jss';
import { ThemeObject } from '../../theme/theme';

export default (theme: ThemeObject): Styles => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    height: '100%',
    background: `linear-gradient(90deg, ${theme.swimlaneArrowBackgroundColor})`,
    zIndex: 10,
    alignItems: 'center',
    justifyItems: 'flex-start',
    padding: '0 20px',
    cursor: 'pointer',
    '& > svg': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      height: 15,
    },
  },
  visible: {
    opacity: 1,
    width: 'auto',
  },
  left: {
    left: 0,
    transform: 'rotate(180deg)',
    '& > svg': {
      left: 10,
    },
  },
  right: {
    right: 0,
    '& > svg': {
      right: 10,
    },
  },
});
