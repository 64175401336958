import StationCollection from '../../models/StationCollection';
import PodcastCollection from '../../models/PodcastCollection';
import Player from '../../models/Player';

import { UniversalCollection, UniversalConfig, UniversalPartnerPlayer } from '../../interfaces';
import Collection from '../../models/Collection';

/**
 * Create models of the raw content
 * @param {UniversalCollection} collection
 * @param {string} type
 * @param {object} config
 * @returns {object} StationCollection | PodcastCollection
 */
export const parseContentModal = (
  collection: UniversalCollection,
  type: string,
  config?: UniversalConfig[]
): Collection | null => {
  if (!collection || !type) {
    return;
  }

  if (type === 'stationCollection') {
    return new StationCollection(collection, config);
  }

  if (type === 'podcastCollection') {
    return new PodcastCollection(collection, config);
  }
  return null;
};

export const parsePlayerModel = (player: UniversalPartnerPlayer): UniversalPartnerPlayer | null => {
  if (!player) {
    return;
  }
  return new Player(player);
};
