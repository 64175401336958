import { reportError } from './reportError';
import { url, os, browser } from './commonData';
import { ErrorDetailsType } from '../types';

const reportSpecificError = async (
  errorDetails: ErrorDetailsType
  //talpaUID: string
): Promise<void> => {
  const errorData = {
    errorType: errorDetails?.errorType,
    distTag: errorDetails?.distTag,
    streamUrl: errorDetails?.streamUrl,
    streamType: errorDetails?.streamType,
    pageUrl: url,
    OS: os,
    browser: browser,
    //talpaUID: talpaUID,
  };
  reportError(errorData);
};

export { reportSpecificError };
