export const breakpoints = {
  xss: 374, // extraExtraSmall
  xs: 499, // extraSmall
  sm: 767, // small
  md: 819, // smallMedium
  sl: 1024, // medium
  ml: 1179, // large
  ll: 1139, // extraLarge
  lg: 1680, // extraExtraLarge
  k: 1920, // 1080p
  kk: 2048, // 2k
  kkk: 2880, // 3k
  kkkk: 3840, // 4k
};

export const mediaQuery = {
  maxWidth: (width: number): string => `@media (max-width: ${width}px)`,
  minWidth: (width: number): string => `@media (min-width: ${width}px)`,
  betweenWidth: (minWidth: number, maxWidth: number): string =>
    `@media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`,
};
