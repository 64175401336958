import React from 'react';

const EpisodesIcon = (): JSX.Element => {
  const path = (
    <g id="V4" stroke="none" strokeWidth="1">
      <g
        id="Desktop----Static-light"
        transform="translate(-503.000000, -153.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g id="NL.FM---Podcast" transform="translate(360.000000, 30.000000)">
          <g id="Atom-/-Icon-/-General-/-Chromecast" transform="translate(140.000000, 117.000000)">
            <path
              // eslint-disable-next-line max-len
              d="M24.75,12 C25.57725,12 26.25,12.67275 26.25,13.5 L26.25,13.5 L26.25,22.5 C26.25,23.32725 25.57725,24 24.75,24 L24.75,24 L11.25,24 C10.42275,24 9.75,23.32725 9.75,22.5 L9.75,22.5 L9.75,13.5 C9.75,12.67275 10.42275,12 11.25,12 L11.25,12 Z M24.75,13.5 L11.25,13.5 L11.25,22.5 L24.7515,22.5 L24.75,13.5 Z M21.75,9 C22.57725,9 23.25,9.67275 23.25,10.5 L23.25,10.5 L23.25,11.25 L21.75,11.25 L21.75,10.5 L8.25,10.5 L8.25,19.5 L9,19.5 L9,21 L8.25,21 C7.42275,21 6.75,20.32725 6.75,19.5 L6.75,19.5 L6.75,10.5 C6.75,9.67275 7.42275,9 8.25,9 L8.25,9 Z M18.75,6 C19.57725,6 20.25,6.67275 20.25,7.5 L20.25,7.5 L20.25,8.25 L18.75,8.25 L18.75,7.5 L5.25,7.5 L5.25,16.5 L6,16.5 L6,18 L5.25,18 C4.42275,18 3.75,17.32725 3.75,16.5 L3.75,16.5 L3.75,7.5 C3.75,6.67275 4.42275,6 5.25,6 L5.25,6 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  );

  return (
    <svg viewBox="0 0 24 18" width="30" height="24">
      {path}
    </svg>
  );
};

export default EpisodesIcon;
