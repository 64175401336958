import React from 'react';
import { createUseStyles } from 'react-jss';
import Loader from './Loader/Loader';
import { DEFAULT_COLOR, DEFAULT_SIZE } from './Loader/Loader.const';
import { styles } from './LoadingPage.styles';

const LoadingPage = (): JSX.Element => {
  const useStyles = createUseStyles(styles, { name: 'Controls' });
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Loader color={DEFAULT_COLOR} size={DEFAULT_SIZE} />
      <p>De pagina wordt geladen</p>
    </div>
  );
};

export default LoadingPage;
