import React, { memo, useEffect, useRef, useState } from 'react';
import { IConfig, ActivePodcastOrStation } from '@4tn/core-audio-player-v2';
import { createUseStyles } from 'react-jss';
import styles from './Swimlane.styles';
import ContentTile from '../Tiles/ContentTile';
import SwimlaneArrow from './SwimlaneArrow';
import isEmpty from '../../util/isEmpty';
import useBrowser from '../../hooks/useBrowser';
import { breakpoints } from '../theme/breakpoints';
import { isSafari } from '../../util/device';
import { Tile } from '../Tiles/ContentTile/ContentTile';
import { usePlayerContext } from '../../store/player-context';
import classNames from 'classnames';

type SwimlaneProps = {
  items: unknown[];
  showControlButton?: boolean;
  rows?: number;
  handleSetActivePodcastOrStation: (item: Tile, config: IConfig) => () => void;
  activePodcastOrStation: ActivePodcastOrStation;
  config: IConfig;
  isOnline: boolean;
} & Record<string, unknown>;

interface ScrollEvent {
  target: HTMLDivElement;
}

const useStyles = createUseStyles(styles, { name: 'Swimlane' });
const Swimlane = ({
  items,
  rows = 1,
  handleSetActivePodcastOrStation,
  activePodcastOrStation,
  config,
  isOnline,
}: SwimlaneProps): JSX.Element => {
  const classes = useStyles();
  const swimlaneRef = useRef<HTMLDivElement>();
  const { playerState } = usePlayerContext();

  //originally false, false
  const [directions, setDirections] = useState({
    left: true,
    right: true,
  });

  const browser = useBrowser();
  const swimlaneId = 'swId';

  useEffect(() => {
    if (swimlaneId) {
      handleScroll({
        target: swimlaneRef.current,
      });
    }
  }, [browser.size, swimlaneId]);

  useEffect(() => {
    swimlaneRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      swimlaneRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [directions]);

  const handleScroll = (e: ScrollEvent | Event) => {
    const wrapper = e.target as HTMLDivElement;
    if (!swimlaneRef.current) {
      // eslint-disable-next-line no-console
      console.log('Swimlane is undefined in Swimlane.ts');
      return;
    }

    const leftOffset = browser.down(breakpoints.sm) ? 20 : 40;
    const rightOffset = browser.down(breakpoints.sm) ? 15 : 45;

    let nextDirections = {};
    if (wrapper.scrollLeft - leftOffset > swimlaneRef.current.scrollLeft && !directions.left) {
      nextDirections = {
        ...nextDirections,
        left: true,
      };
    }
    if (wrapper.scrollLeft - leftOffset <= swimlaneRef.current.scrollLeft && directions.left) {
      nextDirections = {
        ...nextDirections,
        left: false,
      };
    }

    const rightCorner = wrapper.scrollWidth - wrapper.scrollLeft - rightOffset - leftOffset;

    if (rightCorner > swimlaneRef.current.clientWidth && !directions.right) {
      nextDirections = {
        ...nextDirections,
        right: true,
      };
    }

    if (rightCorner <= swimlaneRef.current.clientWidth && directions.right) {
      nextDirections = {
        ...nextDirections,
        right: false,
      };
    }
    if (!isEmpty(nextDirections)) {
      setDirections({
        ...directions,
        ...nextDirections,
      });
    }
  };

  const renderTile = (tile: Tile) => {
    const setActiveSlugOrId = () => {
      if (!tile.media) {
        const splittedPathName = window.location.pathname.split('/');
        const [, , , , channelOrEpisode] = splittedPathName;
        return tile.slug === channelOrEpisode;
      }

      if (activePodcastOrStation?.slug && tile?.slug) {
        return activePodcastOrStation.slug === tile.slug;
      }

      if (activePodcastOrStation?.id && tile?.id) {
        return activePodcastOrStation.id === tile.id;
      }
    };

    const isActive = setActiveSlugOrId();

    return (
      <div
        key={tile.title}
        className={classNames(classes.item, !isOnline || playerState.isLoading ? classes.disabled : null)}
        onClick={!isActive ? handleSetActivePodcastOrStation(tile, config) : null}
      >
        <ContentTile {...tile} isActive={isActive} />
      </div>
    );
  };

  const scroll =
    (direction = 'right') =>
    () => {
      // 50% for small screens
      let percentage = 0.5;

      if (browser.up(breakpoints.ml)) {
        // 25% for other screens
        percentage = 0.25;
      }

      const amount = window.innerWidth * percentage;

      const left =
        direction === 'right' ? swimlaneRef.current?.scrollLeft + amount : swimlaneRef.current?.scrollLeft - amount;

      if (isSafari) {
        const smoothScroll = (nextPoint: number) => {
          swimlaneRef.current?.scrollTo({
            left: nextPoint,
            behavior: 'smooth',
          });
          if (direction === 'right' && nextPoint >= left) {
            return;
          }
          if (direction === 'left' && nextPoint <= left) {
            return;
          }
          setTimeout(() => smoothScroll(direction === 'right' ? nextPoint + 10 : nextPoint - 10));
        };

        smoothScroll(swimlaneRef.current?.scrollLeft);
        return;
      }

      swimlaneRef.current?.scrollTo({
        left,
        behavior: 'smooth',
      });
    };

  //originally false, false
  const { left = true, right = true } = directions;

  const itemsPerRow: unknown | unknown[][] = [];
  let rowNum = 0;
  //for mobile version in two rows (for now)
  if (browser.down(breakpoints.md + 1)) {
    rows = 2;
  }

  const rowList = [...Array(rows)].map((a, idx) => idx);

  items?.forEach((item) => {
    rowList.some((row) => {
      if (rowNum === row) {
        if (!itemsPerRow[row]) {
          itemsPerRow[row] = [];
        }
        itemsPerRow[row].push(item);
        return true;
      }
    });
    rowNum += 1;
    if (rowNum >= rows) {
      rowNum = 0;
    }
  });

  return (
    <div className={classes.swimlane}>
      <SwimlaneArrow visible={left} handleClick={scroll('left')} direction="left" />
      <div id={`wrapper-${swimlaneId}`} key={swimlaneId} className={classes.wrapper} ref={swimlaneRef}>
        <div className={classes.section}>
          {Object.values(itemsPerRow).map((section: Array<Tile>, idx) => (
            <div key={idx} className={classes.items}>
              {section.map(renderTile)}
            </div>
          ))}
        </div>
      </div>
      <SwimlaneArrow visible={right} handleClick={scroll()} direction="right" />
    </div>
  );
};

export default memo(Swimlane);
