import { useContext, useEffect, useRef, useState } from 'react';

import { FlagName, FlagValue, IDefaultRemoteConfigFlags } from './types';
import { DEFAULT_REMOTE_CONFIG_FLAGS } from './const';
import FlagsContext from '../store/flags-context';
import FlagService from './service';

export const useFeatureFlag = (flagName: FlagName): FlagValue => {
  const [flag, setFlag] = useState(DEFAULT_REMOTE_CONFIG_FLAGS[flagName]);
  const flagService = useRef(new FlagService());

  useEffect(() => {
    flagService.current?.getFlagValue(flagName).then((flagValue) => {
      setFlag(flagValue);
    });
  }, []);

  return flag;
};

export const useFlags = (): IDefaultRemoteConfigFlags => {
  const context = useContext(FlagsContext);
  if (!context) {
    return DEFAULT_REMOTE_CONFIG_FLAGS;
  }
  return context;
};
