import classNames from 'classnames';
import React from 'react';
import { convertSecondsToHMS, HMS } from '../../../util/time';
import { formatDate } from '../../../util/date';
import { createUseStyles } from 'react-jss';
import styles from './ContentTile.styles';

const useStyles = createUseStyles(styles, { name: 'ContentTile' });

type Media = {
  key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}[];

export interface Tile {
  title: string;
  contentTypeId: string;
  id: string;
  images: unknown;
  media: Media;
  slug: string;
  publishDate: string;
  durationSeconds: number;
  duration: HMS;
  isActive: boolean;
  feedUrl?: string;
}

const ContentTile = (tile: Tile): JSX.Element => {
  const classes = useStyles();
  const getContentDetail = () => {
    if (tile?.publishDate && tile?.durationSeconds) {
      const { hours, minutes, seconds } = convertSecondsToHMS(tile.durationSeconds);

      return hours >= 1
        ? `${formatDate(tile.publishDate)} - ${hours} u ${minutes} min`
        : `${formatDate(tile.publishDate)} - ${minutes} min ${seconds} sec`;
    }

    return tile?.contentTypeId === 'station' ? 'Radiozender' : 'Podcast';
  };

  return (
    <div
      className={classNames(classes.contentTile, {
        [classes.active]: tile?.isActive,
      })}
    >
      <div
        style={{ backgroundImage: `url(${tile?.images[0]?.uri})` }}
        className={classNames(classes.contentTileImage)}
      />
      <div className={classes.contentTileText}>
        <div className={classNames(classes.contentTileTitle, classes.titleEllipsis)}>{tile?.title}</div>
        <div className={classes.contentType}>{getContentDetail()}</div>
      </div>
    </div>
  );
};

export default ContentTile;
