import React from 'react';

import withSvgIcon from '../../hoc/withSvgIcon';

const CloseIcon = (props): JSX.Element => (
  <svg viewBox="0 0 40 40" width={40} height={40} {...props}>
    <title>{'Close'}</title>
    <path
      // eslint-disable-next-line max-len
      d="m21.414 20 6.293-6.293a.999.999 0 1 0-1.414-1.414L20 18.586l-6.293-6.293a.999.999 0 1 0-1.414 1.414L18.586 20l-6.293 6.293a.999.999 0 1 0 1.414 1.414L20 21.414l6.293 6.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L21.414 20Z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default withSvgIcon(CloseIcon);
