import React from 'react';
import { createUseStyles } from 'react-jss';
import PlayCircleIcon from '../svg/PlayCircleIcon';
import StopIcon from '../svg/StopIcon';
import classNames from 'classnames';
import PauseIcon from '../svg/PauseIcon';
import { styles } from './Controls.styles';

interface PlayButtonProps {
  onClick: () => void;
  isLiveAudio: boolean;
  isPlaying: boolean;
  online: boolean;
}

const PlayButton = ({ onClick, isLiveAudio, isPlaying, online }: PlayButtonProps): JSX.Element => {
  const useStyles = createUseStyles(styles, { name: 'PlayButton' });
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.circle, {
        [classes.offline]: !online,
      })}
      onClick={onClick}
    >
      {isLiveAudio && (isPlaying ? <StopIcon /> : <PlayCircleIcon />)}
      {!isLiveAudio && (isPlaying ? <PauseIcon /> : <PlayCircleIcon />)}
    </div>
  );
};

export default PlayButton;
