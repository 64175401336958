import { Styles } from 'react-jss';
import { breakpoints, mediaQuery } from '../theme/breakpoints';
import { ThemeObject } from '../theme/theme';

const skipButtonsStyles = {
  display: 'inline-block',
  verticalAlign: 'top',
  position: 'relative',
  width: 22,
  // marginTop: 22,
};

const svgStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: 22,
  height: 22,
};

export const styles = (theme: ThemeObject): Styles => ({
  wrapper: {
    alignSelf: 'center',
    zIndex: 10,
    display: 'flex',
  },
  backWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  back: {
    ...skipButtonsStyles,
    cursor: 'pointer',
    marginRight: 19,
    '& > svg': {
      ...svgStyles,
    },
  },
  circle: {
    display: 'flex',
    textAlign: '-webkit-center',
    cursor: 'pointer',
    '& > svg': {
      width: 45,
      height: 45,
      color: theme.buttonColor,
    },
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.7,
  },

  forward: {
    ...skipButtonsStyles,
    cursor: 'pointer',
    marginLeft: 19,
    '& > svg': {
      ...svgStyles,
      transform: 'rotateY(180deg)',
    },
  },
  timing: {
    width: 22,
    lineHeight: '22px',
    textAlign: 'center',
    fontSize: 10,
  },

  [mediaQuery.maxWidth(breakpoints.ml)]: {
    // Keep this commented until we put back removed two tabs
    // wrapper: {
    //   left: '37.5%',
    // },
  },
  [mediaQuery.maxWidth(breakpoints.sl)]: {
    // Keep this commented until we put back removed two tabs
    wrapper: {
      paddingRight: 20,
    },
  },
  [mediaQuery.maxWidth(breakpoints.md)]: {
    wrapper: {
      left: 'auto',
      right: 21,
      transform: 'none',
      margin: 0,
    },
    circle: {
      '& > svg': {
        width: 48,
        height: 48,
      },
    },
    back: {
      marginTop: 15,
    },
    forward: {
      marginTop: 15,
    },
    backWrapper: {
      alignItems: 'baseline',
    },
  },
  [mediaQuery.maxWidth(breakpoints.sm)]: {
    wrapper: {
      paddingRight: 20,
    },
  },
  [mediaQuery.maxWidth(breakpoints.xss)]: {
    wrapper: {
      right: 10,
      paddingRight: 16,
      paddingLeft: 0,
    },
    back: {
      marginRight: 10,
    },
    forward: {
      marginLeft: 10,
    },
  },
});
