import React, { useRef, useState, useEffect, useMemo } from 'react';
import { player, ActivePodcastOrStation } from '@4tn/core-audio-player-v2';
import styles from './Scrubber.styles';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { isMobile } from '../../util/device';
import { addOmnyEvent } from '../../metrics/omnyMetrics';
import {
  calculateCurrentTime,
  formatToMinutes,
  calculateTrackWidth,
  getHorizontalDragValue,
  getPercentageDragged,
} from './Scrubber.utils';
import { usePlayerContext } from '../../store/player-context';
import MediaSession from '../../util/MediaSession';

interface ScrubberProps {
  activePodcast: ActivePodcastOrStation;
  isOnline: boolean;
}

const useStyles = createUseStyles(styles, { name: 'Scrubber' });
const Scrubber = ({ activePodcast, isOnline }: ScrubberProps): JSX.Element => {
  const [percentageDragged, setPercentageDragged] = useState<number>(0);
  const [isScrubbing, setIsScrubbing] = useState<boolean>(false);
  const trackContainerRef = useRef<HTMLDivElement>(null);
  const duration = activePodcast?.durationSeconds || 0;
  const { playerState, currentTime: timePlayed, setCurrentTime } = usePlayerContext();
  const currentTime = isScrubbing ? calculateCurrentTime(duration, percentageDragged) : timePlayed;
  const trackWidth = isScrubbing ? percentageDragged : calculateTrackWidth(duration, currentTime);
  const mediaSession = useMemo(() => MediaSession.getInstance(), []);

  let boundingClientRect: DOMRect = null;
  const classes = useStyles();
  const isTouch = false;

  useEffect(() => {
    if (isScrubbing) {
      const seekTime = calculateCurrentTime(duration, percentageDragged);
      if (currentTime !== seekTime) {
        addOmnyEvent(activePodcast, currentTime, 'Stop');
        addOmnyEvent(activePodcast, seekTime, 'Start');
      }

      player.seek(seekTime);
      setCurrentTime(seekTime);
      mediaSession.setPositionState({ duration, position: seekTime });
    }
  }, [percentageDragged, currentTime]);

  const handleScrubStart = (event: MouseEvent | TouchEvent) => {
    setIsScrubbing(true);
    boundingClientRect = trackContainerRef?.current?.getBoundingClientRect();
    event instanceof MouseEvent ? handleMoveMouse(event) : handleMoveTouch(event);
    addDraggingListeners();
  };

  const handleScrubEnd = () => {
    setIsScrubbing(false);
    removeDraggingListeners();
  };

  const handleMoveMouse = (event: MouseEvent): void => {
    const userInputXPosition = event.pageX;
    const dragAmount = getHorizontalDragValue(userInputXPosition, boundingClientRect);
    const percentageDragged = getPercentageDragged(dragAmount, boundingClientRect);
    setPercentageDragged(percentageDragged);
  };

  const handleMoveTouch = (event: TouchEvent): void => {
    const userInputXPosition =
      event.type === 'touchmove'
        ? event.changedTouches[0].pageX
        : event.type === 'touchstart'
        ? event.touches[0].pageX
        : 0;

    const dragAmount = getHorizontalDragValue(userInputXPosition, boundingClientRect);
    const percentageDragged = getPercentageDragged(dragAmount, boundingClientRect);
    setPercentageDragged(percentageDragged);
  };

  const addDraggingListeners = () => {
    window.addEventListener('mousemove', handleMoveMouse);
    window.addEventListener('touchmove', handleMoveTouch);
    window.addEventListener('mouseup', handleScrubEnd);
    window.addEventListener('touchend', handleScrubEnd);
    window.addEventListener('touchcancel', handleScrubEnd);
  };

  const removeDraggingListeners = () => {
    window.removeEventListener('mousemove', handleMoveMouse);
    window.removeEventListener('touchmove', handleMoveTouch);
    window.removeEventListener('mouseup', handleScrubEnd);
    window.removeEventListener('touchend', handleScrubEnd);
    window.removeEventListener('touchcancel', handleScrubEnd);
  };

  return (
    <div
      className={classNames({
        [classes.container]: !isMobile,
        [classes.scrubberMobile]: isMobile,
        [classes.animation]: !isScrubbing && currentTime > 0,
        [classes.hideOnMobile]: isTouch,
        [classes.disabled]: playerState.isLoading || playerState.hasError || !isOnline,
      })}
    >
      <div
        className={classes.trackContainer}
        ref={trackContainerRef}
        onMouseDown={() => handleScrubStart(event as MouseEvent)}
        onTouchStart={() => handleScrubStart(event as TouchEvent)}
        role="presentation"
      >
        <div className={classes.track} />
        <div className={classes.trackComplete} style={{ width: `${trackWidth}%` }} />
        <div className={classes.handle} style={{ left: `${trackWidth}%` }} />
      </div>
      <div className={classes.timeContainer}>
        <span>{formatToMinutes(currentTime)}</span>
      </div>
    </div>
  );
};

export default Scrubber;
