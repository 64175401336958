export interface IDefaultRemoteConfigFlags {
  showScrubber?: boolean;
  excludePreroll?: boolean;
}

export enum FEATURE_FLAG {
  EXCLUDE_PREROLL = 'excludePreroll',
  SHOW_SCRUBBER = 'showScrubber',
}

export type FlagName = keyof IDefaultRemoteConfigFlags;
export type FlagValue = IDefaultRemoteConfigFlags[FlagName];
