import { Tile } from './../components/Tiles/ContentTile/ContentTile';

/**
 *
 * @returns slug for station or podcast
 */
export const getSlugForStationOrPodcast = (stationsAndPodcasts: unknown, item: Tile): string => {
  const pathStation = getSlug(stationsAndPodcasts['stations'], item);
  const pathPodcast = getSlug(stationsAndPodcasts['podcasts'], item);

  if (pathStation) {
    return pathStation;
  } else {
    return pathPodcast;
  }
};

/**
 *
 * @returns slug for station or podcast
 */
const getSlug = (stationsOrPodcasts: unknown, item: Tile): string => {
  if (!stationsOrPodcasts) {
    return null;
  }

  let path;
  const query = window.location.search;
  Object.entries(stationsOrPodcasts).forEach(([brand, channel]) => {
    const stationOrPodcastSlug = Object.keys(channel).find((key) => key === item.slug);
    if (stationOrPodcastSlug) {
      path = `${item.contentTypeId}s/${brand}/${stationOrPodcastSlug}${query}`;
      return;
    }
  });
  return path;
};

/**
 *
 * @returns slug for podcast episode
 */
export const getSlugForPodcastEpisode = (podcasts: unknown, item: Tile): string => {
  if (!podcasts) {
    return null;
  }

  let path;
  Object.entries(podcasts).forEach(([brand, channel]) => {
    Object.values(channel).find((podcast) => {
      return podcast['getClips']['clips'].find((episode) => {
        if (item.slug === episode.slug) {
          path = `${item.contentTypeId}s/${brand}/${podcast['slug']}/${episode.slug}`;
          return true;
        }
      });
    });
  });
  return path;
};
