import { breakpoints, mediaQuery } from './../../theme/breakpoints';
export const styles = {
  '@keyframes loop': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  spinner: {
    position: 'relative',
    animation: '$loop 1.1s infinite linear',
    backgroundRepeat: 'no-repeat',
  },
  wrapper: {
    width: '45px',
    height: '45px',
    maxHeight: '45px',
  },
  [mediaQuery.maxWidth(breakpoints.md)]: {
    wrapper: {
      width: '48px',
      height: '48px',
    },
  },
};
