import {
  TealiumEventNames,
  TealiumEventCategory,
  TealiumEvent,
  playerStateManager,
  PlayerStatus,
  ActivePodcastOrStation,
  trackLink,
} from '@4tn/core-audio-player-v2';

export const streamLoadMediaEventHandler = (mediaMetadata: ActivePodcastOrStation): void => {
  const { playerVersion } = playerStateManager.getState();

  const tealiumEvent = new TealiumEvent({
    eventName: TealiumEventNames.PLAYER_LOAD_MEDIA,
    eventCategory: TealiumEventCategory.PLAYER,
    player: {
      playerState: PlayerStatus.IDLE,
      playerVersion,
    },
    mediaMetadata,
  });

  trackLink(tealiumEvent.getEventData());
};
