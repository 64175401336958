import React from 'react';

import classNames from 'classnames';
import ArrowSwimlaneIcon from '../../svg/ArrowSwimlane';
import { createUseStyles } from 'react-jss';
import styles from './SwimlaneArrow.styles';
const useStyles = createUseStyles(styles, { name: 'SwimlaneArrow' });

interface SwimlaneArrowProps {
  handleClick: () => void;
  direction: 'left' | 'right';
  visible: boolean;
}

const SwimlaneArrow = ({ visible, direction, handleClick }: SwimlaneArrowProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div
      data-testid={`swimlane-arrow-${direction}`}
      className={classNames(classes.wrapper, classes[direction], {
        [classes.visible]: visible,
      })}
      onClick={handleClick}
    >
      <ArrowSwimlaneIcon />
    </div>
  );
};
export default SwimlaneArrow;
