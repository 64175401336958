import { createUseStyles } from 'react-jss';
import { DEFAULT_COLOR, DEFAULT_SIZE } from './Loader.const';
import spinnerGradient from './spinner-gradient.svg';
import spinnerWhite from './spinner-white.svg';
import React from 'react';
import { styles } from './Loader.styles';

interface LoaderProps {
  color: string;
  size: number;
}

const Loader = ({ color, size }: LoaderProps): JSX.Element => {
  const useStyles = createUseStyles(styles, { name: 'Loader' });
  const classes = useStyles();
  const spinnerColor = color === DEFAULT_COLOR ? `url(${spinnerGradient})` : `url(${spinnerWhite})`;

  return (
    <div className={classes.wrapper}>
      <div className={classes.spinner} style={{ background: spinnerColor, width: size, height: size }} />
    </div>
  );
};

Loader.defaultProps = {
  size: DEFAULT_SIZE,
  color: DEFAULT_COLOR,
};

export default Loader;
