import {
  TealiumEventNames,
  TealiumEventCategory,
  TealiumEvent,
  playerStateManager,
  PlayerStatus,
  PlaybackMode,
  VolumeEventLabel,
  StreamType,
  trackLink,
} from '@4tn/core-audio-player-v2';

export const streamVolumeEventHandler = (
  currentVolume: number,
  nextVolume: number,
  currentTime: number,
  playbackMode: PlaybackMode,
  isPlaying: boolean
): void => {
  const { mediaMetadata, playerVersion } = playerStateManager.getState();

  let eventLabel: VolumeEventLabel;

  if ((currentVolume !== 0 && nextVolume > currentVolume) || (currentVolume === 0 && nextVolume > currentVolume)) {
    eventLabel = VolumeEventLabel.UP;
  }

  if (currentVolume !== 0 && nextVolume < currentVolume) {
    eventLabel = VolumeEventLabel.DOWN;
  }

  const tealiumEvent = new TealiumEvent({
    eventName: TealiumEventNames.PLAYER_VOLUME,
    eventCategory: TealiumEventCategory.PLAYER,
    eventLabel: eventLabel,
    media: {
      mediaOffset: playbackMode === PlaybackMode.ON_DEMAND_CLIP ? currentTime : null,
    },
    player: {
      playerState: isPlaying
        ? PlayerStatus.PLAY
        : mediaMetadata.contentTypeId === StreamType.STATION
        ? PlayerStatus.STOP
        : PlayerStatus.PAUSE,
      playerVersion,
    },
    mediaMetadata,
  });

  trackLink(tealiumEvent.getEventData());
};
