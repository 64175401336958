import { UseQueryResult, useQuery } from 'react-query';
import { UniversalPartnerPlayer } from '../api/interfaces';

const jukeProfile = process.env.REACT_APP_GRAPH_QL_PROFILE;
const graphqlEndpoint = process.env.REACT_APP_GRAPH_QL_ENDPOINT;
const graphqlApiKey = process.env.REACT_APP_GRAPH_QL_API_KEY;

interface UseGQLQueryProps {
  key: string | unknown[];
  query: string;
  variables?: unknown;
  config?: unknown;
}

const fetchGraphQL = async (query: string, variables: unknown) => {
  return fetch(
    `${graphqlEndpoint}?${new URLSearchParams({
      query: query,
      variables: JSON.stringify(variables),
      operationName: 'Player',
    })}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-api-key': graphqlApiKey,
        profile: jukeProfile,
      },
    }
  );
};

export const useGQLQuery = ({
  key,
  query,
  variables,
  config = {
    refetchOnReconnect: false,
  },
}: UseGQLQueryProps): UseQueryResult<{
  player: UniversalPartnerPlayer;
  isLoading: boolean;
  error: unknown;
}> => {
  const callBack = async () => {
    const response = await fetchGraphQL(query, variables);
    const responseJson = await response.json();
    return responseJson?.data;
  };
  return useQuery(key, callBack, config);
};
