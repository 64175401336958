// eslint-disable-next-line @typescript-eslint/no-explicit-any
function cloneDeep<T extends { [key: string]: any }>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  const clone = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      (clone as T)[key] = cloneDeep(obj[key]);
    }
  }

  return clone as T;
}

export default cloneDeep;
