import {
  TealiumEventNames,
  TealiumEventCategory,
  TealiumEvent,
  playerStateManager,
  PlayerStatus,
  trackLink,
  StreamType,
} from '@4tn/core-audio-player-v2';

export function streamClickTealiumEventHandler(label: string, isPlaying: boolean): void {
  const { mediaMetadata, playerVersion } = playerStateManager.getState();

  const tealiumEvent = new TealiumEvent({
    eventName: TealiumEventNames.PLAYER_CLICK,
    eventCategory: TealiumEventCategory.PLAYER,
    eventLabel: label,
    player: {
      playerState: isPlaying
        ? PlayerStatus.PLAY
        : mediaMetadata.contentTypeId === StreamType.STATION
        ? PlayerStatus.STOP
        : PlayerStatus.PAUSE,
      playerVersion,
    },
    mediaMetadata,
  });

  trackLink(tealiumEvent.getEventData());
}
