import { useGQLQuery } from './useGQLQuery';
import { getPlayer } from '../api/graphql/queries/playerQueries';
import getSlugFromPathname from '../util/getSlugFromPathname';
import { QUERY_KEYS } from '../api/queryKeys';
import { UniversalPartnerPlayer } from '../api/interfaces';

export const useFetchData = (): { data: { player: UniversalPartnerPlayer }; isLoading: boolean; error: unknown } => {
  const { data, isLoading, error } = useGQLQuery({
    key: QUERY_KEYS.PLAYER,
    query: getPlayer,
    variables: {
      slug: getSlugFromPathname(),
    },
  });
  return {
    data,
    isLoading,
    error,
  };
};
