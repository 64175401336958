import React from 'react';
import PropTypes from 'prop-types';

const PauseIcon = (): JSX.Element => (
  <svg viewBox="0 0 80 80">
    <title>Stop</title>
    <g stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
      <g>
        <circle id="Elevation" cx="40" cy="40" r="40"></circle>
        <circle id="Surface" fill="currentColor" cx="40" cy="40" r="40"></circle>
        <circle id="Border" fillRule="nonzero" cx="40" cy="40" r="40"></circle>
        <circle id="Transparancy" fillOpacity="0" fill="#000000" fillRule="nonzero" cx="40" cy="40" r="40"></circle>
      </g>
    </g>
    <svg viewBox="0 0 57 57">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(1.000000, 1.000000)">
        <path
          // eslint-disable-next-line max-len
          d="M23.9989965,37.0000427 L20,37.0001703 C18.8954305,37.0001938 17.9999809,36.1047824 17.9999574,35.0002129 C17.9999574,35.0001987 17.9999574,35.0001845 17.9999574,35.0001703 L18,20 C18,18.8954305 18.8954305,18 20,18 L23.9611012,18 C25.0637002,18 25.9583124,18.8923575 25.9610948,19.994953 L25.9989476,34.9949957 C26.001735,36.0995616 25.108567,36.9972489 24.004001,37.0000363 C24.0023329,37.0000405 24.0006647,37.0000426 23.9989965,37.0000427 Z M34.9989965,37.0000427 L31,37.0001703 C29.8954305,37.0001938 28.9999809,36.1047824 28.9999574,35.0002129 C28.9999574,35.0001987 28.9999574,35.0001845 28.9999574,35.0001703 L29,20 C29,18.8954305 29.8954305,18 31,18 L34.9611012,18 C36.0637002,18 36.9583124,18.8923575 36.9610948,19.994953 L36.9989476,34.9949957 C37.001735,36.0995616 36.108567,36.9972489 35.004001,37.0000363 C35.0023329,37.0000405 35.0006647,37.0000426 34.9989965,37.0000427 Z"
          id="pause"
          fill="white"
        />
      </g>
    </svg>
  </svg>
);

PauseIcon.propTypes = {
  hasStroke: PropTypes.bool,
};

PauseIcon.defaultProps = {
  hasStroke: false,
};

export default PauseIcon;
