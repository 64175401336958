import React from 'react';

const PlayCircleIcon = (): JSX.Element => {
  return (
    <svg viewBox="0 0 80 80">
      <title>Play</title>
      <g stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
        <g>
          <circle id="Elevation" cx="40" cy="40" r="40"></circle>
          <circle id="Surface" fill="currentColor" cx="40" cy="40" r="40"></circle>
          <circle id="Border" fillRule="nonzero" cx="40" cy="40" r="40"></circle>
          <circle id="Transparancy" fillOpacity="0" fill="#000000" fillRule="nonzero" cx="40" cy="40" r="40"></circle>
        </g>
        <g id="Group" transform="translate(15.000000, 15.000000)" fill="#FFFFFF" fillRule="nonzero">
          <g id="Atom-/-Icon-/-General-/-Play_filled">
            <path
              // eslint-disable-next-line max-len
              d="M17.5,36.24925 C17.2775,36.24925 17.055,36.18925 16.85625,36.0705 C16.48,35.8455 16.25,35.43925 16.25,34.99925 L16.25,14.99925 C16.25,14.55925 16.48,14.153 16.85625,13.928 C17.23375,13.703 17.70125,13.6905 18.08875,13.8955 L36.83875,23.8955 C37.245,24.113 37.5,24.538 37.5,24.99925 C37.5,25.4605 37.245,25.8855 36.83875,26.103 L18.08875,36.103 C17.90375,36.2005 17.7025,36.24925 17.5,36.24925 L17.5,36.24925 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PlayCircleIcon;
