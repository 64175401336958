import { player, IConfig } from '@4tn/core-audio-player-v2';
import { logger } from '../../util';
import { ActivePodcastOrStation } from '@4tn/core-audio-player-v2';

export const handlePlayStream = async (media: ActivePodcastOrStation, config: IConfig): Promise<void> => {
  try {
    await player.play(media, config);
  } catch (error) {
    logger.error(error);
  }
};

export const handleStopStream = async (): Promise<void> => {
  try {
    await player.stop();
  } catch (error) {
    logger.error(error);
  }
};
