export type ReturnEventType = { [key: string]: string | number | boolean };

export type PodcastEventTypes = { media_duration?: number; media_datepublished?: number; media_program_name?: string };

export type PlayerEvent = {
  media: {
    contentId?: string;
    contentType?: string;
    distTag?: string;
    name?: string;
    player?: string;
    streamType?: string;
  };
  uri?: string;
  streamStatus?: {
    code: string;
  };
};

export interface ErrorDetailsType {
  errorType?: string;
  distTag?: string;
  streamUrl?: string;
  streamType?: string;
  name?: string;
}

export interface PlayerErrorEvent {
  contentId?: string;
  contentType?: string;
  distTag?: string;
  name?: string;
  player?: string;
  streamType?: string;
  streamUrl?: string;
  streamStatus?: {
    code: string;
  };
}

declare global {
  interface Window {
    optimizelyReady: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    utag?: { link?: (param: any) => void };
    __tcfapi?: (str, num, func) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    featureFlags: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    webkitAudioContext: any;
  }
}

export enum SkipDirection {
  FORWARD = 'forward',
  BACKWARD = 'backward',
}

window.optimizelyReady = false;
