export interface HMS {
  hours: number;
  minutes: number;
  seconds: number;
}

export function convertSecondsToHMS(seconds: number): HMS {
  const totalSeconds = seconds;
  if (Number.isNaN(totalSeconds)) {
    return null;
  }
  const h = Math.floor(totalSeconds / 3600);
  const secondsWithoutHours = totalSeconds % 3600;
  const m = Math.floor(secondsWithoutHours / 60);
  const s = secondsWithoutHours % 60;
  return {
    hours: h,
    minutes: m,
    seconds: s,
  };
}

export function convertUtcToUnix(utcTime: string): number {
  return Date.parse(utcTime) / 1000;
}
