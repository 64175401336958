import React, { ReactNode, useContext, useState } from 'react';

type SetPlayerState = React.Dispatch<React.SetStateAction<PlayerState>>;
type SetCurrentTime = React.Dispatch<React.SetStateAction<number>>;
interface PlayerState {
  isPlaying: boolean;
  isLoading: boolean;
  hasError: boolean;
  sessionId?: string | null;
}

interface IPlayerContext {
  playerState: PlayerState;
  setPlayerState: SetPlayerState;
  resetState: () => void;
  currentTime: number;
  setCurrentTime: SetCurrentTime;
}

const INITIAL_STATE = {
  isPlaying: false,
  isLoading: false,
  sessionId: null,
  hasError: false,
};

const PlayerContext = React.createContext<IPlayerContext>({
  playerState: { ...INITIAL_STATE },
  currentTime: 0,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCurrentTime(_time: number) {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setPlayerState(_playerState: PlayerState) {},
  resetState() {},
});

export const PlayerContextProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [playerState, setPlayerState] = useState({ ...INITIAL_STATE });
  const [currentTime, setCurrentTime] = useState(0);
  const resetState = () => setPlayerState(INITIAL_STATE);

  return (
    <PlayerContext.Provider value={{ playerState, setPlayerState, resetState, currentTime, setCurrentTime }}>
      {children}
    </PlayerContext.Provider>
  );
};

export function usePlayerContext(): IPlayerContext {
  const context = useContext(PlayerContext);
  if (context === undefined) {
    throw new Error(`usePlayerContext must be used within a PlayerContextProvider`);
  }
  return context;
}
