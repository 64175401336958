import { CONFIG_FRAGMENT, PODCAST_COLLECTION_FRAGMENT, STATION_COLLECTION_FRAGMENT } from './fragments';

export const getPlayer = `
  query Player($slug: String!) {
    player(slug: $slug) {
			type
			slug
			references {
				... on OverviewCollection {
					id
					items {
						references {
							... on StationCollection {
								${STATION_COLLECTION_FRAGMENT}
							}
							... on PodcastCollection {
								${PODCAST_COLLECTION_FRAGMENT}
							}
						}
						${CONFIG_FRAGMENT}
						type
					}
					slug
					type
				}
			}
			name
			id
			domains
			distTag
			buttonColor
			appearance
			allowUnknownDomains
			images{
				id
				uri
				type
				description
				width
				height
			}
			link
    }
  }
`;
