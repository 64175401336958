export const formatToMinutes = (seconds: number): string => {
  const sign = seconds < 0 ? '-' : '';
  const min = Math.floor(Math.abs(seconds) / 60);
  const sec = Math.floor(Math.abs(seconds) % 60);
  return `${sign + (min < 10 ? '0' : '') + min}:${sec < 10 ? '0' : ''}${sec}`;
};

export const calculateTrackWidth = (durationSeconds: number | undefined, time: number): number => {
  if (!durationSeconds || time > durationSeconds) {
    return 100;
  }
  return Math.max((time / durationSeconds) * 100, 0);
};

export const calculateCurrentTime = (duration: number, percentageDragged: number): number => {
  return duration * (percentageDragged / 100);
};

export const getHorizontalDragValue = (xPos: number, boundingClientRect: DOMRect): number => {
  let draggedX = xPos - (boundingClientRect.left + window.pageXOffset);
  draggedX = Math.max(draggedX, 0);
  draggedX = Math.min(draggedX, boundingClientRect.width);
  return draggedX;
};

export const getPercentageDragged = (dragAmount: number, boundingClientRect: DOMRect): number => {
  const draggedRatio = dragAmount / boundingClientRect.width;
  return draggedRatio * 100;
};
