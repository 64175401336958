import { UniversalPartnerPlayer, UniversalCollection, UniversalImage } from '../../api/interfaces';
import isEmpty from '../../util/isEmpty';

/**
 * @class
 * A representation of a universal Player
 */
export default class UniversalPlayer {
  allowUnknownDomains: boolean;
  appearance: string;
  buttonColor: string;
  distTag: string;
  domains: string[];
  id: string;
  references: UniversalCollection[];
  name: string;
  slug: string;
  type: string;
  images: Array<UniversalImage>;
  link: string;

  constructor(player: UniversalPartnerPlayer) {
    if (isEmpty(player)) {
      return undefined;
    }

    this.allowUnknownDomains = player.allowUnknownDomains;
    this.appearance = player.appearance;
    this.buttonColor = player.buttonColor;
    this.distTag = player.distTag;
    this.domains = player.domains;
    this.id = player.id;
    this.references = player.references;
    this.name = player.name;
    this.slug = player.slug;
    this.type = player.type;
    this.images = player.images;
    this.link = player.link;

    // Make this class read-only
    Object.freeze(this);
  }
}
