import React, { useEffect, useState } from 'react';

export const useContainerWidth = (
  controlsRef: React.MutableRefObject<HTMLDivElement>,
  imageRef: React.MutableRefObject<HTMLImageElement>,
  depsList: unknown[] = []
): string => {
  const GUTTER_WIDTH = 25;
  const [containerWidth, setContainerWidth] = useState('100%');

  useEffect(() => {
    let imageWidth = 0;
    let controlsWidth = 0;
    if (imageRef.current) {
      imageWidth = imageRef.current.offsetWidth;
    }
    if (controlsRef.current) {
      controlsWidth = controlsRef.current.offsetWidth;
    }

    const containerMaxWidth = window.innerWidth - (imageWidth + controlsWidth + GUTTER_WIDTH);
    setContainerWidth(`${containerMaxWidth}px`);
  }, [window.innerWidth, ...depsList]);

  return containerWidth;
};
