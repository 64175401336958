export const fontFamily = '"Amsi Pro Narrow", Arial, sans-serif';
export const fontSizes = {
  xxxsmall: 12,
  xxsmall: 13,
  xsmall: 14,
  small: 16,
  medium: 18,
  // TODO: Remove commented and unused fonts
  // mediumLarge: 21,
  // large: 24,
  // xlarge: 28,
  // xxlarge: 38,
  // xxxlarge: 50,
  // gigantic: 80,
};

export const grey100 = '#F4F4F8';
export const grey200 = '#E9EAEE';
export const grey300 = '#D6D8DE';
export const grey500 = '#8D929E'; // main grey
export const grey600 = '#3A3C42';
export const greyActive = '#616367';
export const green500 = '#46C31D'; // main green
export const green600 = '#3DAC18';
export const blue500 = '#0091FF'; // main blue
export const red = '#C2002F';
export const white = '#FFFFFF';
export const black = '#000000';

export const transparentLightmode010 = 'rgba(0,0,0,.1)';
export const transparentLightmode030 = 'rgba(0,0,0,.3)';
export const transparentDarkmode010 = 'rgba(255,255,255,.1)';
export const transparentDarkmode020 = 'rgba(255,255,255,.2)';
export const transparentDarkmode030 = 'rgba(255,255,255,.3)';

export const swimlaneArrowLightmode = `rgba(244,244,248,0) 0%, rgba(244,244,248,0.64) 43.5%, ${grey100} 100%`;
export const swimlaneArrowDarkmode = `rgba(58,60,66,0) 0%, rgba(58,60,66,0.64) 43.5%, ${grey600} 100%`;

export const ellipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
