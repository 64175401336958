const storage = {
  getItem(key: string): string | null {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return null;
    }
  },

  setItem(key: string, value: string): void {
    try {
      return localStorage.setItem(key, value);
    } catch (e) {}
  },

  removeItem(key: string): void {
    try {
      return localStorage.removeItem(key);
    } catch (e) {}
  },

  clear(): void {
    try {
      return localStorage.clear();
    } catch (e) {}
  },

  isEnabled(): boolean {
    try {
      localStorage.setItem('ls_enabled', 'true');
      localStorage.removeItem('ls_enabled');
      return true;
    } catch (e) {
      return false;
    }
  },
};

export default storage;
