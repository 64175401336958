import * as cssVariable from '../theme/cssVariables';
import { breakpoints, mediaQuery } from '../theme/breakpoints';
import { ThemeObject } from '../theme/theme';
import { Styles } from 'react-jss';

export const styles = (theme: ThemeObject): Styles => ({
  wrapper: {
    alignSelf: 'center',
    display: 'inherit',
  },
  innerWrapper: {
    paddingLeft: 30,
    display: 'inherit',
  },
  image: {
    display: 'inline-block',
    width: 70,
    height: 70,
    borderRadius: 5,
  },
  infoContent: {
    display: 'inline-block',
    verticalAlign: 'top',
    paddingLeft: 15,
    color: theme.titleTextColor,
    alignSelf: 'center',
    maxWidth: 230,
  },
  title: {
    ...cssVariable.ellipsis,
    fontWeight: 700,
    fontSize: cssVariable.fontSizes.medium,
  },
  titlePlaceholder: {
    ...cssVariable.ellipsis,
    fontWeight: 700,
    fontSize: cssVariable.fontSizes.medium,
    visibility: 'hidden',
  },
  subtitle: {
    ...cssVariable.ellipsis,
  },
  subtitlePlaceholder: {
    ...cssVariable.ellipsis,
    maxWidth: 250,
    visibility: 'hidden',
  },

  songName: {
    fontSize: 16,
    fontWeight: 'bold',
    ...cssVariable.ellipsis,
  },
  artistName: {
    fontSize: 14,
    ...cssVariable.ellipsis,
  },

  [mediaQuery.maxWidth(breakpoints.sl)]: {
    innerWrapper: {
      paddingLeft: 30,
    },
  },
  [mediaQuery.maxWidth(breakpoints.md)]: {
    wrapper: {
      position: 'relative',
      width: '100%',
    },
    innerWrapper: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    image: {
      alignSelf: 'center',
      width: 48,
      height: 48,
    },
    title: {
      fontSize: cssVariable.fontSizes.small,
    },
    titlePlaceholder: {
      fontSize: cssVariable.fontSizes.small,
      visibility: 'hidden',
    },
    subtitle: {
      fontSize: cssVariable.fontSizes.xsmall,
    },
    subtitlePlaceholder: {
      fontSize: cssVariable.fontSizes.xsmall,
      visibility: 'hidden',
    },
  },
  [mediaQuery.maxWidth(breakpoints.sl)]: {
    innerWrapper: {
      paddingRight: 25,
    },
  },
  [mediaQuery.maxWidth(440)]: {
    subtitlePlaceholder: {
      maxWidth: 150,
      visibility: 'hidden',
    },
    titlePlaceholder: {
      maxWidth: 150,
      visibility: 'hidden',
    },
    infoContent: {
      paddingLeft: 10,
    },
  },

  [mediaQuery.maxWidth(breakpoints.xss)]: {
    subtitlePlaceholder: {
      maxWidth: 75,
      visibility: 'hidden',
    },
    titlePlaceholder: {
      maxWidth: 75,
      visibility: 'hidden',
    },
  },
  [mediaQuery.minWidth(breakpoints.xs)]: {
    infoContent: {
      maxWidth: 350,
    },
  },
  [mediaQuery.minWidth(breakpoints.md)]: {
    subtitlePlaceholder: {
      maxWidth: 180,
      visibility: 'hidden',
    },
    titlePlaceholder: {
      maxWidth: 150,
      width: 150,
      visibility: 'hidden',
    },
  },
  [mediaQuery.minWidth(820)]: {
    subtitle: {
      maxWidth: 180,
      width: 180,
    },
    title: {
      maxWidth: 180,
      width: 180,
    },
  },
  [mediaQuery.minWidth(920)]: {
    subtitle: {
      maxWidth: 250,
      width: 250,
    },
    subtitlePlaceholder: {
      maxWidth: 250,
      visibility: 'hidden',
    },
    titlePlaceholder: {
      maxWidth: 150,
      width: 150,
      visibility: 'hidden',
    },
  },

  [mediaQuery.maxWidth(breakpoints.xss)]: {
    innerWrapper: {
      padding: [5, 0, 5, 13],
    },
    subtitle: {
      whiteSpace: 'normal',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      textOverflow: 'ellipsis',
    },
    subtitlePlaceholder: {
      whiteSpace: 'normal',
      display: '-webkit-box',
      height: 40,
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      textOverflow: 'ellipsis',
      visibility: 'hidden',
      maxWidth: 50,
    },
    titlePlaceholder: {
      maxWidth: 50,
      visibility: 'hidden',
    },
    image: {
      width: 57,
      height: 57,
    },
  },
});
