import React from 'react';
import { ThemeProvider } from 'react-jss';
import theme from '../theme';
import { useDataContext } from '../../store/data-context';
import Loading from '../LoadingPage/LoadingPage';
interface PlayerThemeProviderProps {
  children: React.ReactNode;
}

export default function PlayerThemeProvider(props: PlayerThemeProviderProps): JSX.Element {
  const { player, isLoading } = useDataContext();
  const { appearance, buttonColor } = player || {};

  let content = <Loading />;
  if (!isLoading) {
    content = <ThemeProvider theme={theme(appearance, buttonColor)}>{props.children}</ThemeProvider>;
  }

  return content;
}
