import * as cssVariable from '../theme/cssVariables';
import { breakpoints, mediaQuery } from '../theme/breakpoints';

export const styles = {
  wrapper: {},
  svgIconWrapper: {
    '& > svg': {
      fill: 'white',
    },
  },
  title: {
    fontSize: cssVariable.fontSizes.xxsmall,
    textAlign: 'center',
    lineHeight: 1.1,
  },

  [mediaQuery.maxWidth(breakpoints.ml)]: {
    wrapper: {
      left: '37.5%',
    },
  },
  [mediaQuery.maxWidth(breakpoints.md)]: {
    wrapper: {
      left: 'auto',
      right: 21,
      transform: 'none',
      margin: 0,
    },
    svgIconWrapper: {
      paddingTop: 50,
      '& > svg': {
        top: '50%',
      },
    },
    title: {
      display: 'none',
    },
  },
  [mediaQuery.maxWidth(breakpoints.xss)]: {
    wrapper: {
      transform: 'scale(0.8)',
    },
  },
};
