import React from 'react';

const RadioPodcastIcon = (): JSX.Element => {
  const path = (
    <g id="V4" stroke="none" strokeWidth="1">
      <g
        id="Desktop----Static-light"
        transform="translate(-465.000000, -150.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g id="NL.FM---Podcast" transform="translate(360.000000, 30.000000)">
          <g id="Atom-/-Icon-/-General-/-Chromecast" transform="translate(100.000000, 117.000000)">
            <path
              // eslint-disable-next-line max-len
              d="M14.9997,5.24985 C15.41445,5.24985 15.7497,5.5851 15.7497,5.99985 C15.7497,6.4146 15.41445,6.74985 14.9997,6.74985 C10.45095,6.74985 6.7497,10.4511 6.7497,14.99985 C6.7497,19.5486 10.45095,23.24985 14.9997,23.24985 C19.5492,23.24985 23.2497,19.5486 23.2497,14.99985 C23.2497,14.5731 23.2092,14.12235 23.1252,13.62435 C23.05695,13.2156 23.33295,12.8286 23.74095,12.76035 C24.1542,12.6936 24.53595,12.96735 24.60495,13.37535 C24.70245,13.9566 24.7497,14.48835 24.7497,14.99985 C24.7497,20.37585 20.3757,24.74985 14.9997,24.74985 C9.6237,24.74985 5.2497,20.37585 5.2497,14.99985 C5.2497,9.62385 9.6237,5.24985 14.9997,5.24985 Z M17.643,3.83985 C17.8845,3.7101 18.17775,3.72135 18.40725,3.86985 L18.40725,3.86985 L22.95975,6.81735 C23.30775,7.0431 23.4075,7.50735 23.1825,7.8546 C22.95675,8.2026 22.4925,8.30085 22.14525,8.07735 L22.14525,8.07735 L18.74325,5.8746 L18.69975,14.50335 C18.72225,14.6676 18.75,14.8296 18.75,14.99985 C18.75,17.0676 17.06775,18.74985 15,18.74985 C14.991,18.74985 14.98275,18.74685 14.97375,18.74685 C14.96475,18.74685 14.95575,18.74985 14.9475,18.74985 C12.87975,18.74985 11.1975,17.0676 11.1975,14.99985 C11.1975,12.9321 12.87975,11.24985 14.9475,11.24985 C14.95575,11.24985 14.96475,11.25285 14.97375,11.25285 C14.98275,11.25285 14.991,11.24985 15,11.24985 C15.8295,11.24985 16.59075,11.5296 17.2125,11.98785 L17.2125,11.98785 L17.25,4.4961 C17.2515,4.22235 17.40225,3.97035 17.643,3.83985 Z M14.97375,12.75285 C13.74525,12.7671 12.75,13.7676 12.75,14.99985 C12.75,16.2321 13.74525,17.2326 14.97375,17.24685 C16.20225,17.2326 17.1975,16.2321 17.1975,14.99985 C17.1975,13.7676 16.20225,12.7671 14.97375,12.75285 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  );

  return (
    <svg viewBox="0 0 20 22" width="26" height="28">
      {path}
    </svg>
  );
};

export default RadioPodcastIcon;
