import {
  TealiumEventNames,
  TealiumEventCategory,
  TealiumEvent,
  playerStateManager,
  PlayerStatus,
  trackLink,
} from '@4tn/core-audio-player-v2';

export const streamPlayTealiumEventHandler = (currentTime: number): void => {
  const { mediaMetadata, playerVersion } = playerStateManager.getState();
  const tealiumEvent = new TealiumEvent({
    eventName: TealiumEventNames.PLAYER_PLAY,
    eventCategory: TealiumEventCategory.PLAYER,
    media: {
      mediaOffset: currentTime,
    },
    player: {
      playerState: PlayerStatus.PLAY,
      playerVersion,
    },
    mediaMetadata,
  });

  trackLink(tealiumEvent.getEventData());
};
