import {
  TealiumEventNames,
  TealiumEventCategory,
  TealiumEvent,
  playerStateManager,
  PlayerStatus,
  trackLink,
  ActivePodcastOrStation,
} from '@4tn/core-audio-player-v2';

export function streamMenuPlayTealiumEventHandler(mediaMetadata: ActivePodcastOrStation, isPlaying?: boolean): void {
  const { playerVersion } = playerStateManager.getState();

  const tealiumEvent = new TealiumEvent({
    eventName: TealiumEventNames.PLAYER_MENU_PLAY,
    eventCategory: TealiumEventCategory.PLAYER,
    eventLabel: mediaMetadata.title,
    player: {
      playerState: isPlaying ? PlayerStatus.PLAY : PlayerStatus.PAUSE,
      playerVersion,
    },
    mediaMetadata,
  });

  trackLink(tealiumEvent.getEventData());
}
