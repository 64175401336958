import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { player, events, type IConfig, PlayerEvents, ActivePodcastOrStation } from '@4tn/core-audio-player-v2';
import { styles } from './Controls.styles';
import SkipIcon from '../svg/SkipIcon';
import { SkipDirection } from '../../types';
import Loader from '../LoadingPage/Loader/Loader';
import { DEFAULT_SIZE } from '../LoadingPage/Loader/Loader.const';
import PlayButton from './PlayButton';
import {
  addOmnyEvent,
  omnyEventsStart,
  omnyEventsStop,
  omnyEventsStreamEnded,
  omnyEventPageHide,
} from '../../metrics/omnyMetrics';
import { streamSkipTealiumEventHandler } from '../../analytics/event-handlers/streamSkipTealiumEventHandler';
import { usePlayerContext } from '../../store/player-context';
import { isAndroid, isTablet } from '../../util/device';
import MediaSession from '../../util/MediaSession';
import { streamPlayTealiumEventHandler } from '../../analytics/event-handlers/streamPlayTealiumEventHandler';
import { handlePlayStream, handleStopStream } from '../App/App.utils';

interface ControlProps {
  media: ActivePodcastOrStation;
  config: IConfig;
  online: boolean;
  podcastTitle: string;
  currentTime: number;
  volumeLevel: number;
  isLiveAudio: boolean;
}

const Controls = React.forwardRef(
  (
    { media, config, online, podcastTitle, currentTime, isLiveAudio, volumeLevel }: ControlProps,
    ref: React.MutableRefObject<HTMLDivElement>
  ) => {
    const useStyles = createUseStyles(styles, { name: 'Controls' });
    const { playerState, setPlayerState, setCurrentTime } = usePlayerContext();
    const [shouldLockMediaSessionPosition, setShouldLockMediaSessionPosition] = useState(false);

    const classes = useStyles();
    const [playout, setPlayout] = useState(null);
    const currentTimeRef = useRef(currentTime);
    const mediaSession = useMemo(() => MediaSession.getInstance(), []);

    useEffect(() => {
      currentTimeRef.current = currentTime;
    }, [currentTime]);

    const onCuePoint = (data) => {
      setPlayout(data);
    };

    const handleShouldLockMediaSessionPosition = useCallback(() => {
      if (isAndroid() && playerState.isPlaying) {
        setShouldLockMediaSessionPosition(true);
      }
    }, [playerState]);

    useEffect(() => {
      events.on(PlayerEvents.TRACK_CUE_POINT, onCuePoint);

      return () => {
        events.removeListener(PlayerEvents.TRACK_CUE_POINT, onCuePoint);
      };
    }, []);

    useEffect(() => {
      setPlayout(null);
    }, [media]);

    useEffect(() => {
      if (playerState.isPlaying) {
        let artist: string;
        let album: string;
        if (media?.title !== mediaSession.metadata?.title) {
          artist = '';
          album = '';
        } else {
          artist = isPodcast ? podcastTitle : mediaSession.metadata?.artist;
          album = mediaSession.metadata?.album;
        }

        const data = {
          artist: playout ? playout?.cuePoint.cuePoint.artistName : artist,
          album: playout ? playout?.cuePoint.cuePoint.cueTitle : album,
          imageURL: media.images[0].uri,
          title: media.title,
        };
        mediaSession.setMetadata(data);
        setNavigatorMediaHandlers();
      } else {
        if (media) {
          const data = {
            artist: mediaSession.metadata?.artist,
            album: mediaSession.metadata?.album,
            imageURL: media?.images?.[0]?.uri,
            title: media?.title,
          };
          mediaSession.setMetadata(data);
          setNavigatorMediaHandlers();
        }
      }
    }, [playout, currentTime, handleShouldLockMediaSessionPosition, podcastTitle]);

    useEffect(() => {
      events.on(PlayerEvents.STREAM_START, onStreamPlay);
      events.on(PlayerEvents.STREAM_RESUMED, onStreamPlay);
      events.on(PlayerEvents.STREAM_PAUSE, streamStop);
      events.on(PlayerEvents.STREAM_STOP, streamStop);
      events.on(PlayerEvents.STREAM_ENDED, streamEnded);

      return () => {
        events.off(PlayerEvents.STREAM_START, onStreamPlay);
        events.off(PlayerEvents.STREAM_RESUMED, onStreamPlay);
        events.off(PlayerEvents.STREAM_PAUSE, streamStop);
        events.off(PlayerEvents.STREAM_STOP, streamStop);
        events.off(PlayerEvents.STREAM_ENDED, streamEnded);
      };
    }, [media, online, currentTime, shouldLockMediaSessionPosition]);

    useEffect(() => {
      const pageHideListener = () => {
        omnyEventPageHide(media, currentTimeRef.current);
      };
      window.addEventListener('pagehide', pageHideListener);
      return () => {
        window.removeEventListener('pagehide', pageHideListener);
      };
    }, [media]);

    const setNavigatorMediaHandlers = async () => {
      mediaSession.setActionHandler('play', async () => {
        if (currentTime && Math.ceil(currentTime) !== media.durationSeconds) {
          mediaSession.setPositionState({ duration: media.durationSeconds, position: currentTime });
          player.resume();
        } else {
          await handlePlayStream(media, { ...config, volume: volumeLevel });
        }
      });

      mediaSession.setActionHandler('pause', async () => {
        if (isPodcast) {
          mediaSession.setPositionState({ duration: media.durationSeconds, position: currentTime });
          player.pause();
        } else {
          mediaSession.setPositionState({ duration: 0, position: 0 });
          await handleStopStream();
        }
      });

      mediaSession.setActionHandler('stop', async () => {
        const duration = isPodcast ? media.durationSeconds : 0;
        mediaSession.setPositionState({ duration, position: 0 });
        await handleStopStream();
      });

      if (isPodcast) {
        mediaSession.setActionHandler('seekbackward', (details) => {
          const deafultSkipTime = -15;
          const skipTime = -details.seekOffset || deafultSkipTime;
          handleSeekMark(skipTime, SkipDirection.BACKWARD);
        });

        mediaSession.setActionHandler('seekforward', (details) => {
          const deafultSkipTime = 30;
          const skipTime = details.seekOffset || deafultSkipTime;
          handleSeekMark(skipTime, SkipDirection.FORWARD);
        });

        mediaSession.setActionHandler('seekto', function (event) {
          mediaSession.setPositionState({ duration: media.durationSeconds, position: event.seekTime });
          if (!isTablet()) {
            handleShouldLockMediaSessionPosition();
          }
          player.seek(event.seekTime);
        });
      }

      if (!isPodcast) {
        mediaSession.setActionHandler('seekbackward', null);
        mediaSession.setActionHandler('seekforward', null);
      }
    };

    const onStreamPlay = () => {
      if (isPodcast && !shouldLockMediaSessionPosition) {
        const position = Math.ceil(currentTime) !== media.durationSeconds ? currentTime : 0;
        mediaSession.setPositionState({ duration: media.durationSeconds, position });
      }

      if (shouldLockMediaSessionPosition) {
        setShouldLockMediaSessionPosition(false);
      }

      if (!isPodcast) {
        mediaSession.setPositionState({ duration: 0, position: 0 });
      }

      omnyEventsStart(media, currentTime);
    };

    const streamStop = () => {
      omnyEventsStop(media, currentTime, playerState.isPlaying);
    };

    const streamEnded = () => {
      omnyEventsStreamEnded(media, currentTime);
    };

    const handleSeekMark = (value: number, skipDirection: SkipDirection) => {
      const seek = currentTime + value;
      if (playerState.isPlaying) {
        addOmnyEvent(media, currentTime, 'Stop');
        addOmnyEvent(media, seek, 'Start');
      }
      streamSkipTealiumEventHandler(Math.abs(value), Math.max(seek, 0), skipDirection, playerState.isPlaying);
      if (!isLiveAudio) {
        mediaSession.setPositionState({ duration: media.durationSeconds, position: seek });
        handleShouldLockMediaSessionPosition();
        4;
        setCurrentTime(Math.max(0, seek));
        player.seek(seek);
      }
    };

    const handleTogglePlay = async () => {
      if (playerState.isLoading) {
        return;
      }

      // Call appropriate player function based on contentTypeId and current player state
      if (media.contentTypeId === 'station') {
        if (playerState.isPlaying) {
          await handleStopStream();
        } else if (!playerState.isPlaying) {
          setPlayerState({ ...playerState, isLoading: true });
          streamPlayTealiumEventHandler(currentTime);
          await handlePlayStream(media, { ...config, volume: volumeLevel });
        }
      } else if (media.contentTypeId === 'podcast') {
        if (playerState.isPlaying && currentTime > 0 && playerState.sessionId !== null) {
          player.pause();
          mediaSession.setPositionState({ duration: media.durationSeconds, position: currentTime });
        } else if (!playerState.isPlaying && (currentTime === 0 || Math.ceil(currentTime) >= media.durationSeconds)) {
          setPlayerState({ ...playerState, isLoading: true });
          streamPlayTealiumEventHandler(currentTime);
          await handlePlayStream(media, { ...config, volume: volumeLevel });
        } else if (!playerState.isPlaying && currentTime > 0 && playerState.sessionId === null) {
          setPlayerState({ ...playerState, isLoading: true });
          streamPlayTealiumEventHandler(currentTime);
          await handlePlayStream(media, { ...config, volume: volumeLevel, currentTime });
        } else if (!playerState.isPlaying) {
          setPlayerState({ ...playerState, isLoading: true });
          player.resume();
          mediaSession.setPositionState({ duration: media.durationSeconds, position: currentTime });
        } else {
          setPlayerState({ ...playerState, isPlaying: false });
          await handleStopStream();
          mediaSession.setPositionState({ duration: media.durationSeconds, position: 0 });
        }
      }
    };

    const isPodcast = media?.contentTypeId === 'podcast';

    return (
      <div ref={ref} className={classNames(classes.wrapper, !online ? classes.disabled : null)}>
        {media ? (
          <>
            {isPodcast && (
              <div className={classes.backWrapper}>
                <div
                  className={classNames(classes.back, {
                    [classes.disabled]: playerState.isLoading || playerState.hasError,
                  })}
                  onClick={() => handleSeekMark(-15, SkipDirection.BACKWARD)}
                >
                  <p className={classes.timing}>15</p>
                  <SkipIcon />
                </div>
              </div>
            )}
            {playerState.isLoading ? (
              <div className={classes.backWrapper}>
                <div className={classes.circle}>
                  <div className={classes.backWrapper}>
                    <Loader color="second" size={DEFAULT_SIZE} />
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.backWrapper}>
                <div className={classes.backWrapper}>
                  <PlayButton
                    onClick={handleTogglePlay}
                    isPlaying={playerState.isPlaying}
                    isLiveAudio={isLiveAudio}
                    online={online}
                  />
                </div>
              </div>
            )}
            {isPodcast && (
              <div className={classes.backWrapper}>
                <div
                  className={classNames(classes.forward, {
                    [classes.disabled]: playerState.isLoading || playerState.hasError,
                  })}
                  onClick={() => handleSeekMark(30, SkipDirection.FORWARD)}
                >
                  <p className={classes.timing}>30</p>
                  <SkipIcon />
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>
    );
  }
);

export default Controls;
