import React, { ReactNode, useRef, useState } from 'react';
import { firebase } from './firebase';
import { getRemoteConfig } from 'firebase/remote-config';
import FlagsContext from '../store/flags-context';
import FlagService from './service';
import { IDefaultRemoteConfigFlags } from './types';

interface FlagsProviderProps {
  defaults: {
    [key: string]: string | number | boolean;
  };
  children: ReactNode;
}

const FlagsProvider = ({ defaults, children }: FlagsProviderProps): JSX.Element => {
  const [flags, setFlags] = React.useState<IDefaultRemoteConfigFlags>(defaults);
  const [flagsFetched, setFlagsFetched] = useState(false);
  const flagService = useRef(new FlagService());
  const remoteConfigRef = useRef(getRemoteConfig(firebase));

  React.useEffect(() => {
    remoteConfigRef.current.defaultConfig = defaults;
    flagService.current
      ?.getAllFlags(remoteConfigRef.current)
      .then((featureFlags) => {
        setFlags(featureFlags);
        setFlagsFetched(true);
      })
      .catch(() => {
        setFlagsFetched(true);
      });
  }, []);

  if (!flagsFetched) {
    return null;
  }

  return <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>;
};

export default FlagsProvider;
