import { parsePlayerModel } from '../api/graphql/parsers/overview';
import { useFetchData } from './useFetchData';

export interface IStationOrPodcast {
  contentTypeId: 'station' | 'podcast';
  id: string;
  slug: string;
  title: string;
  images: unknown[];
  media: unknown[];
  getStations?;
  getPodcasts?;
  getClips?;
  episode: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IStationsAndPodcasts {
  all: IStationOrPodcast[];
  stations?: {
    [stationCollectionSlug: string]: {
      [stationSlug: string]: IStationOrPodcast;
    };
  };
  podcasts?: {
    [podcastCollectionSlug: string]: {
      [podcastSlug: string]: IStationOrPodcast;
    };
  };
}

/**
 * we're transforming the data from the api into a data structure that is more useful for ui
 * a data that we send to ui contains all, stations and/or podcasts
 * all - array of items for the Swimlane- all stations and podcasts
 * stations/podcasts- hashmaps where station/podcast collection slug and station/podcast slug are keys
 * see @IStationsAndPodcasts
 */

export const usePlayerData = (): {
  partnerPlayer: unknown;
  stationsAndPodcasts: unknown;
  isLoading: boolean;
} => {
  const { data, isLoading } = useFetchData();
  const { player } = data || {};
  const references = player && player.references;
  const sections = references && references[0] && references[0].items;

  let stationsAndPodcasts = { all: [] };

  sections?.forEach((section) => {
    const { references } = section;
    const reference = references[0] || {};
    let collectionName, collections, contentTypeId;

    if (reference.type === 'stationCollection') {
      collectionName = 'stations';
      collections = [...references[0].getStations?.stations];
      contentTypeId = 'station';
    }

    if (reference.type === 'podcastCollection') {
      collectionName = 'podcasts';
      collections = [...reference.getPodcasts?.podcasts];
      contentTypeId = 'podcast';
    }

    stationsAndPodcasts = {
      ...stationsAndPodcasts,
      ...getCollection({
        stationsAndPodcasts,
        collectionName,
        referenceSlug: reference.slug,
        collections,
        contentTypeId,
      }),
    };
  });

  const partnerPlayer = parsePlayerModel(player);
  return Object.freeze({ partnerPlayer, stationsAndPodcasts, isLoading });
};

function getCollection({
  stationsAndPodcasts,
  collectionName,
  referenceSlug: collectionSlug,
  collections = [],
  contentTypeId,
}) {
  if (!stationsAndPodcasts[collectionName]) {
    stationsAndPodcasts[collectionName] = {};
  }

  /**
   * temporary obj for station(s)/podcast(s) hashmap wich will be asigned to collectionSlug
   * it's connected to radio station and contains all station's channels
   */
  let cacheCollections = {};
  /**
   * temporary array to grab all extended collections
   * it's connected to radio station and contains all station's channels
   */
  let allCollections = [];

  collections?.forEach((collection) => {
    const extendedCollection = {
      ...collection,
      contentTypeId,
    };

    if (contentTypeId === 'podcast') {
      extendedCollection.getClips.clips = [
        ...extendedCollection.getClips.clips.map((item) => ({ ...item, contentTypeId: 'podcast' })),
      ];
    }

    /**
     * group all channels from radio station/podcast into hashmap
     */
    cacheCollections = {
      ...cacheCollections,
      [collection.slug]: {
        ...extendedCollection,
      },
    };
    allCollections = [
      ...allCollections,
      {
        ...extendedCollection,
      },
    ];
  });

  stationsAndPodcasts = {
    ...stationsAndPodcasts,
    // stations or podcasts
    [collectionName]: {
      ...stationsAndPodcasts[collectionName],
      // radio station or podcast slug
      [collectionSlug]: { ...cacheCollections },
    },
    all: [...stationsAndPodcasts.all, ...allCollections],
  };

  return { ...stationsAndPodcasts };
}
