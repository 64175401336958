import { isMobile } from './util/device';

export const fontFamily = '"Amsi Pro Narrow", Arial, sans-serif';
export const fontWeights = {
  light: 300,
  regular: 400,
  bold: 700,
  black: 800,
};

export const fontSizes = {
  xxxsmall: 8,
  xxsmall: 11,
  xsmall: 14,
  small: 16,
  medium: 18,
  mediumLarge: 21,
  large: 24,
  xlarge: 28,
  xxlarge: 38,
  xxxlarge: 50,
  gigantic: 80,
};

export const letterSpacing = {
  small: 0.34,
  medium: 1.65,
};

export const gutter = isMobile ? 10 : 20;
export const contentOffset = isMobile ? 20 : 40;
export const playerGutter = isMobile ? 27.5 : 20;
export const playerGutterVertical = isMobile ? 16 : 20;

export const collectionTitleSize = isMobile ? 18 : 28;

export const desktopMenuWidth = '15%';
export const desktopMenuWidthSmall = 80;
export const desktopMenuMaxWidth = 216;

export const desktopSmallMenuWidth = 60;

export const mobileMenuWidth = '80%';
export const mobileMenuWidthSmall = '';
export const mobileMenuMaxWidth = 312;

export const mobileHeaderHeight = 60;

export const mobileMenuHeight = 66;
export const defaultMenuHeight = 49;

export const menuButtonFontSize = isMobile ? fontSizes.small : fontSizes.xxsmall;

export const svgIconSize = 24;
export const svgIconSizeSmall = 22;

export const headerButtonWidth = 180;
export const headerButtonHeight = 50;

export const playerWidth = 310;
export const playerMargin = gutter;

/**
 * New color scheme
 * https://projects.invisionapp.com/d/main?origin=v7#/console/21094207/444715633/preview
 */

export const grey100 = '#F4F4F8';
export const grey200 = '#E9EAEE';
export const grey300 = '#D6D8DE';
export const grey400 = '#B6B9C3';
export const grey500 = '#8D929E'; // main grey
export const grey600 = '#3A3C42';

export const green100 = '#F7FFE1';
export const green400 = '#6CD74A';
export const green500 = '#46C31D'; // main green
export const green600 = '#3DAC18';

export const blue500 = '#0091FF'; // main blue
export const yellow500 = '#FACF18';
export const red500 = '#FF3E3E';
export const white = '#FFFFFF';
export const black = '#000000';

export const transparentLightmode005 = 'rgba(0,0,0,.05)';
export const transparentLightmode010 = 'rgba(0,0,0,.1)';
export const transparentLightmode030 = 'rgba(0,0,0,.3)';
export const transparentLightmode040 = 'rgba(0,0,0,.4)';

export const transparentDarkmode000 = 'rgba(255, 255, 255, 0)';
export const transparentDarkmode030 = 'rgba(255, 255, 255, .3)';
export const transparentDarkmode050 = 'rgba(255, 255, 255, .5)';

export const transparentGrey500 = 'rgba(141, 146, 158, .8)';
export const transparentGreen500 = 'rgba(70, 195, 29, .4)';

/**
 *
 */

export const transitionDelay = '200ms';
export const transitionTime = '200ms';

export const footerHeight = '140px';

export const carouselArrowHeight = isMobile ? '30px' : '50px';

export const borderRadius = {
  default: 4,
};

export const linearGradient = 'linear-gradient(122deg, #3c9cdc, #37b376 38%, #46C31D 67%, #facf18)';
export const linearGradientPromotionBanner = 'linear-gradient(122deg, #46C31D, #93C01F 62.14%, #FACF18 100%)';
export const backgroundGradient = {
  backgroundImage: linearGradient,
  backgroundPosition: 'center',
  backgroundSize: '167%',
};

export const contentPadding = ({ smallScreen = false }) => ({
  paddingLeft: smallScreen ? 20 : 40,
  paddingRight: smallScreen ? 20 : 40,
  paddingBottom: smallScreen ? 20 : 40,
});

export const fullWidthStyles = (theme, offset = 0) => ({
  marginLeft: -(40 + offset * 2),
  marginRight: -(40 + offset * 2),
  [theme && theme.breakpoints.down('sm')]: {
    marginLeft: -(20 + offset),
    marginRight: -(20 + offset),
  },
});

export const podcastPageHeaderPadding = ({ browser = {}, smallScreen }) => ({
  paddingLeft: smallScreen || (browser.down || {}).sl ? 20 : 40,
  paddingRight: smallScreen || (browser.down || {}).sl ? 20 : 40,
  paddingBottom: 0,
  paddingTop: 40,
});

export const fillAspectRatio = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

export const pageTitle = {
  margin: 0,
  minHeight: isMobile ? fontSizes.xlarge : fontSizes.xxlarge,
  fontSize: isMobile ? fontSizes.xlarge : fontSizes.xxlarge,
  lineHeight: 1,
  fontWeight: fontWeights.bold,
  textTransform: 'uppercase',
  zIndex: 1,
};

export const fullPageMinHeight = {
  minHeight: isMobile ? 'calc(100vh - 340px)' : `calc(100vh - ${footerHeight})`,
};

export const ellipsis = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const focusOutline = {
  outline: '0',
  boxShadow: 'none',
  textDecoration: 'none',
};

export const enableFocusOutlineCSS = `
  body.accessibility input:focus, 
    body.accessibility button:focus,
    body.accessibility textarea:focus,
    body.accessibility select:focus,
    body.accessibility a:focus {
      box-shadow: inset 0px 0px 0px 3px ${blue500};
    }
    body.accessibility a.genre-tile-default:focus {
      outline: 3px solid ${blue500};
    }
    body.accessibility .SocialMediaShareButton:focus,
    body.accessibility .social-share-button > a:focus svg {
      -webkit-transform: scale(1.20);
      -ms-transform: scale(1.20);
      transform: scale(1.20);
    }
    body.accessibility .campaign-page-template a:focus, 
    body.accessibility .campaign-page-template button:focus{
      box-shadow: inset 0px 0px 0px 3px ${grey500};
    }
  `;

export const headerText = {
  fontWeight: 'normal',
  lineHeight: 1.33,
  width: '70%',
  '@media (max-width: 1420px)': {
    width: '100%',
  },
};

export const headerSubtitle = {
  fontSize: fontSizes.medium,
  // lineHeight is unitless value
  lineHeight: `${fontSizes.medium}px`,
  fontWeight: fontWeights.bold,
  marginBottom: 2,
  marginTop: 0,
};

export const headerDescription = {
  marginTop: 0,
  fontSize: fontSizes.medium,
};

// For hiding text which should still be seen by screen readers and web crawlers
// See: https://a11yproject.com/posts/how-to-hide-content
export const screenReadersOnly = {
  position: 'absolute',
  width: 1,
  height: 1,
  padding: 0,
  margin: -1,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  border: 0,
};

export const backgroundCenterCoverNoRepeat = {
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

export const backdropGray = {
  background: grey300,
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
};

/* this one is used to replace SuggestedLink components on Error pages and in Alert in player.
   since eslint reports bad usage of `a` links when switching from Glamorous to JSS,
   we should use `button` with this class to keep styles according to design
*/
export const suggestedLinkButton = {
  background: 'none',
  margin: 0,
  padding: 0,
  outline: 'none',
  border: 'none',
  textTransform: 'uppercase',
  display: 'inline-block',
  color: blue500,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
};

export const WEBKIT_LINE_CLAMP = '-webkit-line-clamp';

export const WEBKIT_BOX_ORIENT = '-webkit-box-orient';

/**
 * Description styles to be used in both `supports()` and as a class for IE11 which doesn't support `supports()`.
 */
export const description2LinesStyles = {
  /* styles for '...' */
  overflow: 'hidden',
  hyphens: 'none',
  position: 'relative',
  lineHeight: '1.2em',
  maxHeight: '3.4em', // two lines + 1em marginTop of p
  textAlign: 'justify',
  paddingRight: '0.6em',
  /* create the ... */
  '&::before': {
    content: "'...'",
    position: 'absolute',
    right: 0,
    bottom: '0px',
  },
  /* hide ... if we have text, which is less than or equal to max lines */
  '&::after': {
    content: "''",
    position: 'absolute',
    right: 0,
    width: '1em',
    height: '1em',
    marginTop: '0.2em',
    background: 'white',
  },
};

/**
 * Converts hex string to rgba string.
 * @param {string} hexString - hex string
 * @param {number} [opacity=1] - number in range [0, 1];
 */
export const hexToRgba = (hexString = grey500, opacity = 1) => {
  hexString = hexString.replace('#', '');
  const r = parseInt(hexString.substring(0, 2), 16);
  const g = parseInt(hexString.substring(2, 4), 16);
  const b = parseInt(hexString.substring(4, 6), 16);

  let rgbaValues = `${r}, ${g}, ${b}`;
  if ((opacity && opacity >= 0 && opacity <= 1) || opacity === 0) {
    rgbaValues += `, ${opacity}`;
  }
  return `rgba(${rgbaValues})`;
};

export const baseButtonStyle = {
  border: 'none',
  background: 'none',
  height: 40,
  padding: '0 20px',
  textAlign: 'center',
  lineHeight: '38px',
  borderRadius: 4,
  display: 'block',
};

export const onAirBadgeStyles = {
  marginRight: 5,
  padding: '4px 10px',
  backgroundColor: red500,
  borderRadius: 2,
  color: white,
  textTransform: 'uppercase',
  fontWeight: fontWeights.bold,
  fontSize: fontSizes.xsmall,
};
